import {
  MulticityFlights,
  ShopMulticitySummaryRequest,
} from "@b2bportal/air-shopping-api";
import {
  TripSummary,
  TripDetails,
  PassengerPricing,
  Prediction,
  WatchAlertView,
  CallState,
  IFlightGridFares,
  OfferWithSuggested,
  Flights,
  FlightShopType,
  GetSimilarFlightsResponse,
  TransferResponse,
  AncillaryFetchOfferSuccess,
  CfarId,
  ChfarId,
  MissedConnectionId,
  DelayId,
  TravelWalletOffer,
  RefundableFaresProperties,
  FlightSortOption,
  FlightEntryTypeEnum,
  FiatPrice,
  ShopSummaryRequest,
  Payment,
} from "redmond";
import {
  PricePredictionGradientVariantType,
  DefaultVariantType,
  PRICE_PREDICTION_GRADIENT,
  PRICE_WATCH_PUSH,
  THEBES_HACKER_FARES_IN_CAP1,
  VIVariantType,
  THEBES_VIRTUAL_INTERLINING_IN_CAP1,
  PRICE_WATCH_PUSH_DEFAULT_OPT_IN,
  CFAR_MULTI_CITY,
  PRICE_FREEZE_NEW_REVIEW_CTA,
  CORP_HIDE_PRICE_DROP_EXPERIMENT,
  HOTEL_CROSS_SELL_V3_EXPERIMENT,
  HotelCrossSellV3VariantType,
} from "../../../context/experiments";
import { actions, actionTypes } from "../actions";
import { formatTripSummaries } from "./utils";
import { ErrorCode } from "@b2bportal/air-price-watch-api";

export enum FlightShopStep {
  PricePrediction,
  ChooseDeparture,
  ChooseReturn,
  ReviewItinerary,
  FareDetails,
  Customize,
}

export type ProductRedeemChoice =
  | "missed_connection"
  | "missed_connection_vi"
  | "delay";

export enum MulticityFlightShopStep {
  ChooseDeparture0,
  ChooseDeparture1,
  ChooseDeparture2,
  ChooseDeparture3,
  ChooseDeparture4,
  ReviewItinerary,
  FareDetails,
  Customize,
  BookTrip,
}

export type MulticityFlightShopChooseDepartureSteps =
  | MulticityFlightShopStep.ChooseDeparture0
  | MulticityFlightShopStep.ChooseDeparture1
  | MulticityFlightShopStep.ChooseDeparture2
  | MulticityFlightShopStep.ChooseDeparture3
  | MulticityFlightShopStep.ChooseDeparture4;

export const DO_NOT_APPLY_OPTION_KEY = "do-not-apply-option";
export const CORP_FINTECH_SUBSCRIPTION_KEY = "corp-fintech-subscription";
export interface ITripSummariesById {
  [key: string]: TripSummary;
}

export interface ITripDetailsByTripId {
  [key: string]: TripDetails;
}

export type TripSummaryId = string;

export interface ITripPricing {
  fare?: PassengerPricing[];
}

export interface IFlightGridFareMap {
  [sliceId: string]: IFlightGridByOutgoingRating;
}

export interface IFlightGridByOutgoingRating {
  [outgoingRating: number]: IFlightGridFares;
}

export interface IReturnSlicesByOutgoingId {
  [outgoingSliceId: string]: ITripIdsByReturnSlice;
}

export interface ITripIdsByReturnSlice {
  [returnSliceId: string]: TripSummaryId;
}

export interface ISelectedTrip {
  tripId: string | null;
  outgoingSliceId: string | null;
  outgoingFareId: string | null;
  outgoingFareRating?: number;
  returnSliceId?: string | null;
  returnFareId?: string | null;
  returnFareRating?: number;
}

export interface ISelectedMulticityTrip {
  tripId: string | null;
  departureSliceId: string | null;
  departureFareId: string | null;
  departureFareRating?: number;
}

export interface IWatchState {
  watches: WatchAlertView[];
  createWatchCallState: CallState;
  createWatchFailureCodes: ErrorCode[] | null;
  updateWatchCallState: CallState;
  listWatchCallState: CallState;
  deleteWatchCallState: CallState;
}

export interface IFlightShopExperiments {
  [PRICE_PREDICTION_GRADIENT]: PricePredictionGradientVariantType;
  [PRICE_WATCH_PUSH]: DefaultVariantType;
  [PRICE_WATCH_PUSH_DEFAULT_OPT_IN]: DefaultVariantType;
  // VI EXPERIMENTS
  [THEBES_HACKER_FARES_IN_CAP1]: VIVariantType;
  [THEBES_VIRTUAL_INTERLINING_IN_CAP1]: VIVariantType;
  [CFAR_MULTI_CITY]: DefaultVariantType;
  [PRICE_FREEZE_NEW_REVIEW_CTA]: DefaultVariantType;
  [CORP_HIDE_PRICE_DROP_EXPERIMENT]: DefaultVariantType;
  [HOTEL_CROSS_SELL_V3_EXPERIMENT]: HotelCrossSellV3VariantType;
}

export interface IFlightShopExperimentState {
  experiments: IFlightShopExperiments | null;
}

export interface IFlightShopState
  extends IWatchState,
    IFlightShopExperimentState {
  tripSummariesById: ITripSummariesById;
  flightGridFares: { [sliceId: string]: IFlightGridFares } | null;
  returnFlightsByOutgoingId: IReturnSlicesByOutgoingId;
  selectedTrip: ISelectedTrip | ISelectedMulticityTrip;
  selectedMulticityTrips: ISelectedMulticityTrip[];
  tripSummariesError: boolean;
  tripSummariesErrorCode: string | null;
  tripSummariesLoading: boolean | null;
  predictionError: boolean;
  tripDetailsLoading: boolean | null;
  predictionLoading: boolean | null;
  tripDetailsById: ITripDetailsByTripId;
  progress: FlightShopStep;
  multicityProgress: MulticityFlightShopStep;
  sortOption: FlightSortOption;
  prediction: Prediction | null;
  rerunPrediction: boolean;
  openFlightShopCalendarDesktop: boolean;
  openFlightShopCalendarMobile: boolean;
  priceFreezeOffer: OfferWithSuggested | null;
  flights: Flights | null;
  multicityFlights: MulticityFlights | null;
  flightShopType: FlightShopType;
  selectedFlightIndex: number | null;
  // note: trips (of type TripSummary[]) is stored separately through the SET_TRIP_SUMMARIES event
  similarFlightsResponse: Omit<GetSimilarFlightsResponse, "trips"> | null;
  fetchSimilarFlightsCallState: CallState;
  transferToSimilarFlightsResponse: TransferResponse | null;
  fetchTransferToSimilarFlightsCallState: CallState;
  ancillaryOfferResponse: AncillaryFetchOfferSuccess | null;
  fetchAncillaryOfferCallState: CallState;
  selectedCfarId: CfarId | null;
  selectedChfarId: ChfarId | null;
  hasSelectedRefundableFare: boolean;
  refundableFaresProperties: RefundableFaresProperties;
  selectedDisruptionProtectionId: MissedConnectionId | DelayId | null;
  hasPriceFreezeOnOutbound: boolean;
  bestOfferOverall: TravelWalletOffer | undefined;
  offersByTripId: { [key: string]: TravelWalletOffer } | undefined;
  flightShopEntryPoint?: FlightEntryTypeEnum;
  productRedeemChoice?: ProductRedeemChoice;
  shopRequest: ShopSummaryRequest | ShopMulticitySummaryRequest | undefined;
  policyLimit: FiatPrice | null | undefined;
  recommendedBasedOnPreferences?: boolean;
  selectedMarketingAirlineCodes: string[];
  selectedOperatingAirlineCodes: string[];
  paymentMethods: Payment[];
  listPaymentMethodCallState: CallState;
}

export const initialState: IFlightShopState = {
  tripSummariesById: {},
  flightGridFares: null,
  returnFlightsByOutgoingId: {},
  tripDetailsById: {},
  selectedTrip: {
    tripId: null,
    outgoingSliceId: null,
    outgoingFareId: null,
  },
  selectedMulticityTrips: [
    { tripId: null, departureSliceId: null, departureFareId: null },
    { tripId: null, departureSliceId: null, departureFareId: null },
  ],
  tripSummariesError: false,
  tripSummariesErrorCode: null,
  predictionError: false,
  tripSummariesLoading: null,
  predictionLoading: null,
  tripDetailsLoading: null,
  progress: FlightShopStep.ChooseDeparture,
  multicityProgress: MulticityFlightShopStep.ChooseDeparture0,
  sortOption: FlightSortOption.FareScore,
  prediction: null,
  rerunPrediction: false,
  watches: [],
  createWatchCallState: CallState.NotCalled,
  createWatchFailureCodes: null,
  updateWatchCallState: CallState.NotCalled,
  listWatchCallState: CallState.NotCalled,
  deleteWatchCallState: CallState.NotCalled,
  openFlightShopCalendarDesktop: false,
  openFlightShopCalendarMobile: false,
  priceFreezeOffer: null,
  flights: null,
  multicityFlights: null,
  flightShopType: FlightShopType.DEFAULT,
  selectedFlightIndex: null,
  similarFlightsResponse: null,
  fetchSimilarFlightsCallState: CallState.NotCalled,
  transferToSimilarFlightsResponse: null,
  fetchTransferToSimilarFlightsCallState: CallState.NotCalled,
  ancillaryOfferResponse: null,
  fetchAncillaryOfferCallState: CallState.NotCalled,
  selectedCfarId: null,
  selectedChfarId: null,
  hasSelectedRefundableFare: false,
  refundableFaresProperties: {},
  selectedDisruptionProtectionId: null,
  hasPriceFreezeOnOutbound: false,
  bestOfferOverall: undefined,
  offersByTripId: undefined,
  experiments: null,
  flightShopEntryPoint: undefined,
  productRedeemChoice: undefined,
  shopRequest: undefined,
  policyLimit: undefined,
  selectedMarketingAirlineCodes: [],
  selectedOperatingAirlineCodes: [],
  paymentMethods: [],
  listPaymentMethodCallState: CallState.NotCalled,
};

export const getExperimentState = (
  flightShopState: IFlightShopState | undefined
): IFlightShopExperimentState => {
  return {
    experiments: flightShopState?.experiments ?? initialState.experiments,
  };
};

export function reducer(
  state: IFlightShopState = initialState,
  action: actions.FlightShopActions
): IFlightShopState {
  switch (action.type) {
    case actionTypes.FETCH_TRIP_SUMMARIES:
      return {
        ...state,
        rerunPrediction: false,
        tripSummariesLoading: true,
      };

    case actionTypes.FETCH_TRIP_SUMMARIES_FOR_PREDICTION:
      return {
        ...state,
        predictionLoading: true,
        tripSummariesLoading: true,
        rerunPrediction: false,
      };

    // note: upon calling a shopSummary request, it will reset fetchAncillaryOfferCallState, therefore allowing the user to fetchAncillaryOffer again
    case actionTypes.FETCH_TRIP_SUMMARIES_V2:
      return {
        ...state,
        rerunPrediction: false,
        tripSummariesLoading: true,
        fetchAncillaryOfferCallState: CallState.NotCalled,
      };

    case actionTypes.FETCH_TRIP_SUMMARIES_FOR_PREDICTION_V2:
      return {
        ...state,
        predictionLoading: true,
        tripSummariesLoading: true,
        rerunPrediction: false,
      };

    case actionTypes.FETCH_TRIP_SUMMARIES_V3:
      return {
        ...state,
        predictionLoading: true,
        rerunPrediction: false,
        tripSummariesLoading: true,
        fetchAncillaryOfferCallState: CallState.NotCalled,
      };

    case actionTypes.FETCH_MULTICITY_TRIP_SUMMARIES:
      return {
        ...state,
        predictionLoading: false,
        rerunPrediction: false,
        tripSummariesLoading: true,
        fetchAncillaryOfferCallState: CallState.NotCalled,
      };

    case actionTypes.SET_TRIP_SUMMARIES_ERROR: {
      return {
        ...state,
        tripSummariesById: {},
        returnFlightsByOutgoingId: {},
        tripSummariesError: true,
        tripSummariesErrorCode: action.errorCode || null,
        predictionLoading: false,
        tripSummariesLoading: false,
      };
    }

    case actionTypes.RERUN_PREDICTION: {
      return {
        ...state,
        rerunPrediction: true,
      };
    }

    case actionTypes.SET_PREDICTION_ERROR: {
      return {
        ...state,
        prediction: null,
        predictionError: true,
      };
    }

    case actionTypes.SET_PREDICTION: {
      return {
        ...state,
        prediction: action.prediction,
        predictionError: false,
        predictionLoading: false,
      };
    }

    case actionTypes.SET_SELECTED_FLIGHT_INDEX: {
      return {
        ...state,
        selectedFlightIndex: action.selectedFlightIndex,
      };
    }

    case actionTypes.SET_PRICE_PRICE_FRREEZE_OFFER: {
      return {
        ...state,
        priceFreezeOffer: action.payload,
      };
    }

    case actionTypes.FETCH_TRIP_DETAILS:
      return {
        ...state,
        tripDetailsLoading: true,
      };

    case actionTypes.SET_TRIP_SUMMARIES: {
      const { tripSummaries } = action;
      const { tripSummariesById, returnFlightsByOutgoingId } =
        formatTripSummaries(tripSummaries);

      return {
        ...state,
        tripSummariesById: { ...tripSummariesById },
        returnFlightsByOutgoingId: { ...returnFlightsByOutgoingId },
        tripSummariesLoading: false,
        tripSummariesError: false,
        predictionLoading: false,
      };
    }

    case actionTypes.SET_FLIGHTS: {
      const { flights } = action;

      return {
        ...state,
        flights,
        multicityFlights: null,
        tripSummariesLoading: false,
        tripSummariesError: false,
      };
    }

    case actionTypes.SET_MULTICITY_FLIGHTS: {
      const { flights } = action;

      return {
        ...state,
        flights: null,
        multicityFlights: flights,
        tripSummariesLoading: false,
        tripSummariesError: false,
      };
    }

    case actionTypes.SET_TRIP_DETAILS: {
      const { tripDetails } = action;
      const tripDetailsById = {
        ...state.tripDetailsById,
        [tripDetails.id]: tripDetails,
      };
      return {
        ...state,
        tripDetailsLoading: false,
        tripDetailsById,
      };
    }

    case actionTypes.SET_CHOSEN_OUTGOING_SLICE: {
      const {
        outgoingSliceId,
        outgoingFareRating,
        outgoingFareId,
        tripId,
        resetReturnIds,
      } = action;
      const selectedTrip = {
        ...state.selectedTrip,
        tripId,
        outgoingSliceId,
        outgoingFareId: outgoingFareId || null,
        outgoingFareRating: outgoingFareRating,
        // resetting the return ids are sometimes required depending on the current progress of FlightShop workflow
        ...(resetReturnIds && {
          returnSliceId: null,
          returnFareId: null,
        }),
      };
      return {
        ...state,
        selectedTrip,
      };
    }

    case actionTypes.SET_CHOSEN_RETURN_SLICE: {
      const { returnSliceId, returnFareId, returnFareRating, tripId } = action;
      const selectedTrip = {
        ...state.selectedTrip,
        tripId,
        returnSliceId,
        returnFareRating,
        returnFareId: returnFareId || null,
      };
      return {
        ...state,
        selectedTrip,
      };
    }

    case actionTypes.SET_CHOSEN_DEPARTURE_SLICE: {
      const {
        departureSliceId,
        departureFareRating,
        departureFareId,
        tripId,
        departureSliceIndex,
      } = action;
      const selectedTrip: ISelectedMulticityTrip = {
        tripId,
        departureSliceId,
        departureFareId: departureFareId || null,
        departureFareRating: departureFareRating,
      };
      let selectedMulticityTrips: ISelectedMulticityTrip[] = [
        ...state.selectedMulticityTrips,
      ];
      // remove the flights that are selected after this index and force user to choose again
      selectedMulticityTrips.splice(departureSliceIndex);
      selectedMulticityTrips[departureSliceIndex] = selectedTrip;

      if (departureSliceIndex === 0) {
        selectedMulticityTrips[1] = {
          tripId: null,
          departureSliceId: null,
          departureFareId: null,
        };
      }

      return {
        ...state,
        selectedMulticityTrips,
        selectedTrip,
      };
    }

    case actionTypes.RESET_SELECTED_TRIP: {
      return {
        ...state,
        selectedTrip: {
          tripId: null,
          outgoingSliceId: null,
          outgoingFareId: null,
        },
        selectedMulticityTrips: [
          { tripId: null, departureSliceId: null, departureFareId: null },
          { tripId: null, departureSliceId: null, departureFareId: null },
        ],
      };
    }

    case actionTypes.SET_FLIGHT_SHOP_TYPE: {
      return {
        ...state,
        flightShopType: action.flightShopType,
      };
    }

    case actionTypes.SET_FLIGHT_SHOP_PROGRESS: {
      const { progress } = action;

      return {
        ...state,
        progress,
      };
    }

    case actionTypes.SET_MULTICITY_FLIGHT_SHOP_PROGRESS: {
      const { progress } = action;

      return {
        ...state,
        multicityProgress: progress,
      };
    }

    case actionTypes.FETCH_SIMILAR_FLIGHTS: {
      return {
        ...state,
        similarFlightsResponse: null,
        fetchSimilarFlightsCallState: CallState.InProcess,
      };
    }

    case actionTypes.SET_SIMILAR_FLIGHTS_RESPONSE: {
      return {
        ...state,
        similarFlightsResponse: action.response,
        fetchSimilarFlightsCallState: action.callState,
      };
    }

    case actionTypes.SET_FETCH_SIMILAR_FLIGHTS_CALL_STATE_FAILED: {
      return {
        ...state,
        fetchSimilarFlightsCallState: CallState.Failed,
      };
    }

    case actionTypes.FETCH_TRANSFER_TO_SIMILAR_FLIGHTS: {
      return {
        ...state,
        transferToSimilarFlightsResponse: null,
        fetchTransferToSimilarFlightsCallState: CallState.InProcess,
      };
    }

    case actionTypes.SET_TRANSFER_TO_SIMILAR_FLIGHTS_RESPONSE: {
      return {
        ...state,
        transferToSimilarFlightsResponse: action.response,
        fetchTransferToSimilarFlightsCallState: action.callState,
      };
    }

    case actionTypes.SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_FAILED: {
      return {
        ...state,
        fetchTransferToSimilarFlightsCallState: CallState.Failed,
      };
    }

    case actionTypes.SET_FETCH_TRANSFER_TO_SIMILAR_FLIGHTS_CALL_STATE_NOT_CALLED: {
      return {
        ...state,
        fetchTransferToSimilarFlightsCallState: CallState.NotCalled,
      };
    }

    case actionTypes.FETCH_ANCILLARY_OFFER: {
      return {
        ...state,
        ancillaryOfferResponse: null,
        selectedCfarId: action.preserveCfarId ? state.selectedCfarId : null,
        selectedDisruptionProtectionId: action.preserveDisruptionProtectionId
          ? state.selectedDisruptionProtectionId
          : null,
        fetchAncillaryOfferCallState: CallState.InProcess,
      };
    }

    case actionTypes.SET_FETCH_ANCILLARY_OFFER_RESPONSE: {
      return {
        ...state,
        ancillaryOfferResponse: action.response,
        fetchAncillaryOfferCallState: CallState.Success,
      };
    }

    case actionTypes.SET_FETCH_ANCILLARY_OFFER_CALL_STATE_FAILED: {
      return {
        ...state,
        fetchAncillaryOfferCallState: CallState.Failed,
      };
    }

    case actionTypes.RESET_FETCH_ANCILLARY_OFFER_STATE: {
      return {
        ...state,
        ancillaryOfferResponse: null,
        selectedCfarId: action.preserveCfarId ? state.selectedCfarId : null,
        selectedDisruptionProtectionId: action.preserveDisruptionProtectionId
          ? state.selectedDisruptionProtectionId
          : null,
        fetchAncillaryOfferCallState: CallState.NotCalled,
      };
    }

    case actionTypes.SET_SELECTED_CFAR_ID: {
      return {
        ...state,
        selectedCfarId: action.cfarId,
      };
    }

    case actionTypes.SET_SELECTED_CHFAR_ID: {
      return {
        ...state,
        selectedChfarId: action.chfarId,
      };
    }

    case actionTypes.SET_HAS_SELECTED_REFUNDABLE_FARE: {
      return {
        ...state,
        hasSelectedRefundableFare: action.hasSelectedRefundableFare,
      };
    }

    case actionTypes.UPDATE_REFUNDABLE_FARES_PROPERTIES: {
      return {
        ...state,
        refundableFaresProperties: {
          ...state.refundableFaresProperties,
          ...action.properties,
        },
      };
    }

    case actionTypes.RESET_REFUNDABLE_FARES_PROPERTIES: {
      return {
        ...state,
        refundableFaresProperties: initialState.refundableFaresProperties,
      };
    }

    case actionTypes.SET_SELECTED_DISRUPTION_PROTECTION_ID: {
      return {
        ...state,
        selectedDisruptionProtectionId: action.disruptionProtectionId,
      };
    }

    case actionTypes.SET_HAS_PRICE_FREEZE_ON_OUTBOUND: {
      return {
        ...state,
        hasPriceFreezeOnOutbound: action.hasPriceFreezeOnOutbound,
      };
    }

    case actionTypes.SET_SORT_OPTION: {
      const { sortOption } = action;

      return {
        ...state,
        sortOption,
      };
    }

    case actionTypes.CREATE_WATCH:
      return {
        ...state,
        createWatchCallState: CallState.InProcess,
      };

    case actionTypes.SET_CREATE_WATCH_CALL_STATE:
      return {
        ...state,
        createWatchCallState: action.callState,
        createWatchFailureCodes: action.failureCodes ?? null,
      };

    case actionTypes.UPDATE_WATCH:
      return {
        ...state,
        updateWatchCallState: CallState.InProcess,
      };

    case actionTypes.SET_UPDATE_WATCH_CALL_STATE:
      return {
        ...state,
        updateWatchCallState: action.callState,
      };

    case actionTypes.DELETE_WATCH:
      return {
        ...state,
        deleteWatchCallState: CallState.InProcess,
      };

    case actionTypes.SET_DELETE_WATCH_CALL_STATE:
      return {
        ...state,
        deleteWatchCallState: action.callState,
      };

    case actionTypes.LIST_WATCHES:
      return {
        ...state,
        listWatchCallState: CallState.InProcess,
      };

    case actionTypes.SET_LIST_WATCHES_CALL_STATE:
      return {
        ...state,
        listWatchCallState: action.callState,
      };

    case actionTypes.SET_WATCHES:
      return {
        ...state,
        watches: action.watches,
      };

    case actionTypes.SET_OPEN_FLIGHT_SHOP_CALENDAR_DESKTOP:
      return {
        ...state,
        openFlightShopCalendarDesktop: action.openFlightShopCalendarDesktop,
      };

    case actionTypes.SET_OPEN_FLIGHT_SHOP_CALENDAR_MOBILE:
      return {
        ...state,
        openFlightShopCalendarMobile: action.openFlightShopCalendarMobile,
      };

    case actionTypes.SET_BEST_OFFER_OVERALL:
      return {
        ...state,
        bestOfferOverall: action.payload,
      };

    case actionTypes.SET_OFFERS_BY_TRIP_ID:
      return {
        ...state,
        offersByTripId: action.payload,
      };

    case actionTypes.SET_FLIGHT_SHOP_EXPERIMENTS:
      return {
        ...state,
        experiments: action.experiments,
      };
    case actionTypes.SET_FLIGHT_SHOP_ENTRY_POINT:
      return {
        ...state,
        flightShopEntryPoint: action.entryPoint,
      };
    case actionTypes.SET_FLIGHT_SHOP_RECOMMENDED_BASED_ON_PREFERENCES:
      return {
        ...state,
        recommendedBasedOnPreferences: action.recommendedBasedOnPreferences,
      };
    case actionTypes.SET_PRODUCT_REDEEM_CHOICE:
      return {
        ...state,
        productRedeemChoice: action.productRedeemChoice,
      };

    case actionTypes.SET_POLICY_LIMIT:
      return {
        ...state,
        policyLimit: action.policyLimit,
      };
    case actionTypes.SET_SHOP_REQUEST: {
      const { shopRequest } = action;

      return {
        ...state,
        shopRequest,
      };
    }
    case actionTypes.SET_SELECTED_MARKETING_AIRLINE_CODES: {
      const { marketingAirlineCodes } = action;
      return {
        ...state,
        selectedMarketingAirlineCodes: marketingAirlineCodes,
      };
    }

    case actionTypes.SET_SELECTED_OPERATING_AIRLINE_CODES: {
      const { operatingAirlineCodes } = action;
      return {
        ...state,
        selectedOperatingAirlineCodes: operatingAirlineCodes,
      };
    }

    case actionTypes.LIST_PAYMENT_METHODS:
      return {
        ...state,
        listPaymentMethodCallState: CallState.InProcess,
      };

    case actionTypes.SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.paymentMethods,
        listPaymentMethodCallState: CallState.Success,
      };

    case actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED:
      return {
        ...state,
        listPaymentMethodCallState: CallState.Failed,
      };

    default:
      return state;
  }
}

export * from "./selectors";
export * from "./utils";
