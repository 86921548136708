import React from "react";
import { RouteComponentProps } from "react-router";
import { Box, Typography } from "@material-ui/core";
import {
  Prices,
  FiatPrice,
  RewardsPrice,
  TripCategory,
  PriceDropProtection,
  PriceDropProtectionEnum,
  VIEWED_PRICE_DROP_DETAILS,
} from "redmond";
import {
  Icon,
  IconName,
  ActionButton,
  getTotalPriceText,
  getRewardsString,
  twoDecimalFormatter,
  tripTypeText,
} from "halifax";
import clsx from "clsx";
import { PriceDropProtection as PriceDropProtectionDetails } from "../../../../book/components/PriceDropProtection";
import { trackEvent } from "../../../../../api/v0/analytics/trackEvent";
import * as constants from "./constants";
import { AddOnPricingBreakdownConnectorProps } from "./container";
import "./styles.scss";
import { useSelector } from "react-redux";
import { getPriceDropRefundTypeSelector } from "../../../../ancillary/reducer";
import { CORP_FINTECH_SUBSCRIPTION_KEY } from "../../../reducer";
import { getCorpFintechSubscriptionPrices } from "../AddOnCustomize/constants";

export interface IAddOnPricingBreakdownProps
  extends AddOnPricingBreakdownConnectorProps,
    RouteComponentProps {
  isPriceFreezeExercise?: boolean;
  actionButtonProps?: {
    message: string | JSX.Element;
    disabled?: boolean;
    onContinue: () => void;
    onClickWhenDisabled?: () => void;
  };
  isMobile?: boolean;
  hasError?: boolean;
}

interface IPricingItem {
  title: string;
  price?: string;
  priceJSX?: JSX.Element;
  icon?: IconName;
  className?: string;
}

const CorpFintechSubscriptionPrice = ({ price }: { price: FiatPrice }) => {
  const { origPrice, discountedPrice } =
    getCorpFintechSubscriptionPrices(price);
  return (
    <>
      <del>{origPrice}</del>{" "}
      <span className="green-text">{discountedPrice}</span>
    </>
  );
};

export const AddOnPricingBreakdown = (props: IAddOnPricingBreakdownProps) => {
  const {
    selectedFare,
    priceFreezeChargeAmount,
    totalPrices: totalPricesFromShop,
    priceFreezeTotalPrices,
    tripCategory: tripCategoryFromShop,
    tripCategoryFromPriceFreeze,
    prediction: predictionFromShop,
    priceDropProtectionFromPriceFreeze,
    rewardsKey,
    isPriceFreezeExercise,
    cfarOfferPrices,
    discountedCfarOffer,
    disruptionProtectionOfferPrices,
    actionButtonProps,
    isMobile,
    isRapidRebookRenameEnabled,
    isAirOfferRedesignEnabled,
    hasError,
    cfarOfferCoverage,
    isCfarCoMerchEnabled,
    selectedDisruptionProtectionId,
  } = props;

  const priceDropRefundType = useSelector(getPriceDropRefundTypeSelector);
  const isCorpFintechSubscription =
    selectedDisruptionProtectionId?.productId ===
      CORP_FINTECH_SUBSCRIPTION_KEY && disruptionProtectionOfferPrices;

  const {
    baseFare,
    taxesFees,
    cfar,
    disruptionProtection,
    priceDropProtection,
    totalPriceOrRewards,
    flightType,
  }: {
    baseFare?: string;
    taxesFees?: string;
    cfar?: string;
    disruptionProtection?: string;
    priceDropProtection?: PriceDropProtection | null;
    totalPriceOrRewards: string;
    flightType: string;
  } = (() => {
    const {
      selectedFareAmount,
      totalPrices,
      tripCategory,
      priceDropProtection,
    }: {
      selectedFareAmount:
        | { baseAmount?: Prices; taxAmount?: Prices }
        | undefined;
      totalPrices: {
        fiat: FiatPrice;
        rewards: RewardsPrice | undefined;
      } | null;
      tripCategory: TripCategory;
      priceDropProtection?: PriceDropProtection | null;
    } = (() => {
      if (isPriceFreezeExercise) {
        return {
          selectedFareAmount: priceFreezeChargeAmount
            ? { baseAmount: priceFreezeChargeAmount }
            : undefined,
          tripCategory: tripCategoryFromPriceFreeze ?? TripCategory.ONE_WAY,
          totalPrices: priceFreezeTotalPrices,
          priceDropProtection: priceDropProtectionFromPriceFreeze,
        };
      } else {
        return {
          selectedFareAmount: selectedFare
            ? {
                baseAmount: selectedFare?.paxPricings?.[0]?.pricing?.baseAmount,
                taxAmount: selectedFare?.paxPricings?.[0]?.pricing?.taxAmount,
              }
            : undefined,
          tripCategory: tripCategoryFromShop,
          totalPrices: totalPricesFromShop,
          priceDropProtection: predictionFromShop?.priceDropProtection,
        };
      }
    })();

    return {
      baseFare: getFormattedTotalPriceText(
        selectedFareAmount?.baseAmount?.fiat
      ),
      taxesFees: getFormattedTotalPriceText(
        selectedFareAmount?.taxAmount?.fiat
      ),
      cfar: getFormattedTotalPriceText(cfarOfferPrices?.fiat),
      disruptionProtection: getFormattedTotalPriceText(
        disruptionProtectionOfferPrices?.fiat
      ),
      totalPriceOrRewards: totalPrices
        ? constants.getPriceAndRewardsCopy({
            price: getTotalPriceText({
              price: totalPrices.fiat,
              priceFormatter: twoDecimalFormatter,
            }),
            rewards:
              rewardsKey && totalPrices.rewards
                ? getRewardsString(totalPrices.rewards)
                : undefined,
            separator: constants.OR_SEPARATOR,
          })
        : "",
      flightType: constants.getPerTravelerCopy(tripTypeText[tripCategory]),
      priceDropProtection,
    };
  })();

  const renderPricingItems = (pricingItems: (IPricingItem | undefined)[]) => {
    const renderPricingItem = (props: IPricingItem) => {
      const { title, price, icon, className, priceJSX } = props;
      return (
        <Box className={clsx("pricing-item-wrapper", className)} key={title}>
          <Box className="pricing-item-label">
            {icon && (
              <Box className="pricing-item-icon-wrapper">
                <Icon className="pricing-item-icon" name={icon} />
              </Box>
            )}
            <Typography variant="body2" className="pricing-item-copy">
              {title}
            </Typography>
          </Box>
          <Typography variant="body2" className="pricing-copy">
            {priceJSX ?? price}
          </Typography>
        </Box>
      );
    };

    return pricingItems.map((item) =>
      item ? renderPricingItem(item) : undefined
    );
  };

  return (
    <Box
      className={clsx("add-on-pricing-breakdown-root", { mobile: isMobile })}
    >
      <Box className="add-on-pricing-breakdown-container">
        <Box className="checkout-breakdown-header-section">
          <Typography className="header-copy" variant="h2">
            {constants.CHECKOUT_BREAKDOWN_HEADER}
          </Typography>
        </Box>
        <Box className="pricing-breakdown-section">
          {renderPricingItems([
            baseFare
              ? {
                  title: constants.BASE_FARE_COPY,
                  price: baseFare,
                }
              : undefined,
            taxesFees
              ? {
                  title: constants.TAXES_FEES_COPY,
                  price: taxesFees,
                }
              : undefined,
            disruptionProtection && !isCorpFintechSubscription
              ? {
                  title: isRapidRebookRenameEnabled
                    ? constants.DISRUPTION_PROTECTION_COPY_NEW
                    : constants.DISRUPTION_PROTECTION_COPY_OLD,
                  price: disruptionProtection,
                  icon: IconName.DisruptionProtectionBlue,
                }
              : undefined,
            isCorpFintechSubscription
              ? {
                  title: isRapidRebookRenameEnabled
                    ? constants.DISRUPTION_PROTECTION_COPY_NEW
                    : constants.DISRUPTION_PROTECTION_COPY_OLD,
                  priceJSX: (
                    <CorpFintechSubscriptionPrice
                      price={disruptionProtectionOfferPrices?.fiat}
                    />
                  ),
                  icon: IconName.DisruptionProtectionBlue,
                }
              : undefined,
            cfar
              ? {
                  title: isCfarCoMerchEnabled
                    ? `${constants.CFAR_COPY}, ${cfarOfferCoverage?.cashCoveragePercentage}% coverage`
                    : constants.CFAR_COPY,
                  price: cfar,
                  icon: IconName.CheckShieldBlue,
                  priceJSX: discountedCfarOffer ? (
                    <>
                      <span className="cross-out">
                        {getFormattedTotalPriceText(
                          discountedCfarOffer.originalPremiumAmount.fiat
                        )}
                      </span>{" "}
                      <span className="green-text">{cfar}</span>
                    </>
                  ) : undefined,
                }
              : undefined,
          ])}
        </Box>
        {priceDropProtection &&
          priceDropProtection?.PriceDropProtection ===
            PriceDropProtectionEnum.IsEligible && (
            <PriceDropProtectionDetails
              priceDropProtection={priceDropProtection}
              onClick={() =>
                trackEvent({
                  eventName: VIEWED_PRICE_DROP_DETAILS,
                  properties: {
                    page: "customize",
                    refund_type: priceDropRefundType,
                  },
                })
              }
              hideSubtitle={true}
            />
          )}
        <Box className="total-price-section">
          <Typography
            variant="body2"
            className="total-price-copy"
            dangerouslySetInnerHTML={{
              __html: totalPriceOrRewards,
            }}
          />
          <Typography
            variant="body2"
            className="flight-type-copy"
            dangerouslySetInnerHTML={{
              __html: flightType,
            }}
          />
        </Box>
        {!!actionButtonProps && (
          <Box className="continue-cta-section">
            <ActionButton
              className="continue-cta"
              defaultStyle="h4r-primary"
              onClick={actionButtonProps.onContinue}
              onClickWhenDisabled={actionButtonProps.onClickWhenDisabled}
              message={actionButtonProps.message}
              disabled={actionButtonProps.disabled}
            />
          </Box>
        )}
        {isAirOfferRedesignEnabled && actionButtonProps?.disabled && (
          <div
            className={clsx("air-offer-redesign-validation-text", {
              hasError: hasError,
            })}
          >
            {constants.VALIDATION_TEXT}
          </div>
        )}
      </Box>
    </Box>
  );
};

const getFormattedTotalPriceText = (fiat: FiatPrice | undefined) => {
  if (fiat) {
    return getTotalPriceText({
      price: fiat,
      priceFormatter: twoDecimalFormatter,
    });
  }
  return "";
};
