import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { AddOnCustomize } from "./component";
import {
  isCfarAvailableSelector,
  isChfarAvailableSelector,
  isDisruptionProtectionAvailableSelector,
  selectedCfarIdSelector,
  selectedChfarIdSelector,
  selectedDisruptionProtectionIdSelector,
  selectedCfarOfferPricesSelector,
  selectedChfarOfferPricesSelector,
  selectedDisruptionProtectionOfferPricesSelector,
  fetchAncillaryOfferCallStateSelector,
  cfarAddOnChoicePropertiesSelector,
  isOptionSelectionCompleteSelector,
  isCfarOptionSelectionCompleteSelector,
  isDpOptionSelectionCompleteSelector,
  dpAddOnChoicePropertiesSelector,
  dpOfferPropertiesForViewBookingAddOnSelector,
  cfarAttachPropertiesForViewBookingAddOnSelector,
  viewBookingAddOnPropertiesSelector,
  hasActiveRefundableFareInFlightShopSelector,
  selectedDiscountedCfarOfferPricesSelector,
  cfarOfferSelector,
  cfarDiscountPropertiesSelector,
  selectedCfarOfferCoverageSelector,
  selectedFareDetailsSelector,
  useGroupedAncillariesSelector,
} from "../../../reducer/selectors";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../../rewards/reducer/selectors";
import {
  populateFlightBookQueryParams,
  scheduleQuote,
} from "../../../../book/actions/actions";
import {
  getDpOptionSelectionProgressSelector,
  getCfarOptionSelectionProgressSelector,
  getCheckoutStepNumberSelector,
} from "../../../../book/reducer";
import {
  fetchAncillaryOffer,
  setSelectedCfarId,
  setSelectedChfarId,
} from "../../../actions/actions";
import { IStoreState } from "../../../../../reducers/types";
import {
  cfarSocialVariantSelector,
  isRapidRebookRenameEnabledSelector,
  cfarDiscountPromoCopy,
  isCfarCoMerchEnabledSelector,
  isChfarVariantDeclineAll,
} from "../../../../ancillary/reducer";
import { isAirOfferRedesignEnabledSelector } from "../../../../ancillary/reducer";
import {
  priceFreezeChargeAmountPricesSelector,
  priceFreezeFareTaxAmountSelector,
} from "../../../../freeze/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    isCfarAvailable: isCfarAvailableSelector(state),
    isDisruptionProtectionAvailable:
      isDisruptionProtectionAvailableSelector(state),
    selectedCfarId: selectedCfarIdSelector(state),
    isChfarAvailable: isChfarAvailableSelector(state),
    isChfarVariantDeclineAll: isChfarVariantDeclineAll(state),
    useGroupedAncillaries: useGroupedAncillariesSelector(state),
    selectedChfarId: selectedChfarIdSelector(state),
    chfarOfferPrices: selectedChfarOfferPricesSelector(state),
    selectedDisruptionProtectionId:
      selectedDisruptionProtectionIdSelector(state),
    cfarOfferPrices: selectedCfarOfferPricesSelector(state),
    disruptionProtectionOfferPrices:
      selectedDisruptionProtectionOfferPricesSelector(state),
    rewardsKey: getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    fetchAncillaryOfferCallState: fetchAncillaryOfferCallStateSelector(state),
    cfarAddOnChoiceProperties: cfarAddOnChoicePropertiesSelector(state),
    dpAddOnChoiceProperties: dpAddOnChoicePropertiesSelector(state),
    isOptionSelectionComplete: isOptionSelectionCompleteSelector(state),
    isCfarOptionSelectionComplete: isCfarOptionSelectionCompleteSelector(state),
    isDpOptionSelectionComplete: isDpOptionSelectionCompleteSelector(state),
    viewBookingAddOnProperties: viewBookingAddOnPropertiesSelector(state),
    dpOfferPropertiesForViewBookingAddOn:
      dpOfferPropertiesForViewBookingAddOnSelector(state),
    cfarOfferPropertiesForViewBookingAddOn:
      cfarAttachPropertiesForViewBookingAddOnSelector(state),
    getDpOptionSelectionProgress: getDpOptionSelectionProgressSelector(state),
    getCfarOptionSelectionProgress:
      getCfarOptionSelectionProgressSelector(state),
    getCheckoutStepNumber: getCheckoutStepNumberSelector(state),
    hasActiveRefundableFare: hasActiveRefundableFareInFlightShopSelector(state),
    cfarSocialVariant: cfarSocialVariantSelector(state),
    isRapidRebookRenameEnabled: isRapidRebookRenameEnabledSelector(state),
    cfarDiscountPromoCopy: cfarDiscountPromoCopy(state),
    discountedCfarOffer: selectedDiscountedCfarOfferPricesSelector(state),
    cfarOffer: cfarOfferSelector(state),
    cfarDiscountProperties: cfarDiscountPropertiesSelector(state),
    isAirOfferRedesignEnabled: isAirOfferRedesignEnabledSelector(state),
    isCfarCoMerchEnabled: isCfarCoMerchEnabledSelector(state),
    selectedCfarOfferCoverage: selectedCfarOfferCoverageSelector(state),
    selectedFare: selectedFareDetailsSelector(state),
    pfFare: priceFreezeChargeAmountPricesSelector(state),
    priceFreezeTaxAmount: priceFreezeFareTaxAmountSelector(state),
  };
};

const mapDispatchToProps = {
  populateFlightBookQueryParams,
  fetchAncillaryOffer,
  scheduleQuote,
  setSelectedCfarId,
  setSelectedChfarId,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AddOnCustomizeConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedAddOnCustomize = withRouter(connector(AddOnCustomize));
