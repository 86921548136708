import { createSelector } from "reselect";
import { uniq, isEqual, isEmpty, cloneDeep } from "lodash-es";
import {
  TripSummary,
  TripDetails,
  AirlineCode,
  FareDetails,
  TripCategory,
  Prediction,
  WatchAlertView,
  CallState,
  ShopFilter,
  SliceStopCountFilter,
  RegionType,
  AlertKeyEnum,
  FlightKey,
  TripSegment,
  SelectedSliceProperties,
  ViewedTripSummaryProperties,
  ViewedFlightListProperties,
  Dealness,
  getHopperFareRatingName,
  AirEntryProperties,
  AirMultiCityEntryProperties,
  ViewedPriceFreezeProperties,
  FiatPrice,
  RewardsPrice,
  Flights,
  Outbound,
  OutboundFares,
  PriceDropProtectionEnum,
  PriceDropViewedProperties,
  IsEligible,
  FlightShopType,
  AncillaryFetchOfferRequest,
  AncillaryOfferEnum,
  CfarOffer,
  FetchCfarOfferSuccessV2,
  MissedConnectionOffer,
  DelayOffer,
  CfarAttachProperties,
  TripAndFareClassFactsProperties,
  UtasPolicy,
  Prices,
  Offer,
  TravelWalletOffer,
  ITrackingProperties,
  PriceFreezeUserSelectedDurationProperties,
  getPlusDaysFromItinerarySegments,
  Cap1DpOfferFactsProperties,
  Cap1DisruptionAddOnChoiceProperties,
  FlightFare,
  getPriceBucketValueFromDealness,
  FlightSortOption,
  ViewedMulticityFlightListProperties,
  CfarDiscount,
  Slice,
  StatementCreditDetail,
  MultiTicketTypeEnum,
  CfarOfferPolicyData,
  CorpFareDetails,
} from "redmond";

import {
  AirChfarOfferV1,
  Prices as AirChfarOfferPrices,
} from "@b2bportal/air-chfar-api";

import { IStoreState } from "../../../../reducers/types";
import {
  customPriceFreezeOfferSelector,
  priceFreezeMinMaxDurationsPropertiesSelector,
  isPriceFreezeDurationEnabledSelector,
} from "../../../freeze/reducer/selectors/priceFreezeDuration";
import {
  getHasSetOutboundTimeRange,
  getHasSetReturnTimeRange,
  getOutboundDepartureTimeRange,
  getOutboundArrivalTimeRange,
  getReturnArrivalTimeRange,
  getReturnDepartureTimeRange,
  getMaxPriceFilter,
  getStopsOption,
  getFareclassOptionFilter,
  getHasSetAirlineFilter,
  getHasSetAirportFilter,
  getHasSetPolicyFilter,
  getHasSetFlightNumberFilter,
  getAirlineFilter,
  getAirportFilter,
  getFlightNumberFilter,
  getHasSetStopsOption,
  getHasSetFareClassFilter,
  getTripCategory,
  getStopsOptionFilter,
  getDepartureDate,
  getReturnDate,
  getOrigin,
  getDestination,
  getPassengersTotal,
  passengerCountSelector,
  getMulticityRoutes,
  getBaggageTransfersFilter,
  getDurationFilter,
  getHasSetDurationFilter,
  getPolicyFilter,
  getApplyUserFlightPreferences,
  getUserFlightPreferencesNotAvailable,
} from "../../../search/reducer/selectors";
import { initialFilterOptions, IFilterState } from "../../../search/reducer";
import {
  FlightShopStep,
  IReturnSlicesByOutgoingId,
  ISelectedTrip,
  ITripDetailsByTripId,
  ITripIdsByReturnSlice,
  ITripSummariesById,
  DO_NOT_APPLY_OPTION_KEY,
  ISelectedMulticityTrip,
  MulticityFlightShopStep,
  CORP_FINTECH_SUBSCRIPTION_KEY,
} from "../index";
import * as filters from "../utils/processFilters";
import * as sorters from "../utils/processSort";
import dayjs from "dayjs";
import { IPriceFreezeOfferEntries, IFlightSummaryData } from "halifax";
import {
  getSliceDetails,
  getCustomizeCheckoutBreakdownTotalPrices,
} from "../utils";
import { getFtcTypeArr } from "../../utils/helpers";
import {
  getAgentEmail,
  getAllowRewardsRedemptionInAnyAccount,
  getIsFirstLaunch,
  getRewardsAccounts,
  getSelectedAccount,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../../../rewards/reducer";
import { IFlightListData } from "../../v2/components/FlightList";
import {
  isDisruptionProtectionEnabledSelector,
  selectedFlightsFromRebookSummarySelector,
  rebookSummaryCallStateSelector,
  disruptionProtectionOriginalSliceSelector,
  disruptionProtectionOriginalSliceContextSelector,
  isFlightBookWithAncillariesEnabledSelector,
  isRefundableFaresEnabledSelector,
  cfarOffersSelector,
  batchCfarOffersCallStateSelector,
  isCfarEnabledSelector,
  isChfarEnabledSelector,
  getPriceDropRefundTypeSelector,
  hasUpdatedCfarOffersForReturnFlightsSelector,
  isCfarF9NKEnabledSelector,
} from "../../../ancillary/reducer";
import {
  isCfarEligible,
  getCfarChangePolicyFromAncillaryOfferResponse,
} from "../../../ancillary/utils";
import { PARTIALLY_REBOOKING_TEXT } from "../../../ancillary/constants";
import { getAddedLegacyPrices } from "../../../book/reducer/utils/pricingHelpers";
import {
  getCreditBreakdown,
  getTravelWalletCredit,
} from "../../../travel-wallet/reducer";
import {
  FareSliceDeparture,
  MulticityFlights,
  SliceDeparture,
} from "@b2bportal/air-shopping-api";
import { isFlightMultiTicketType } from "../../v2/components/FlightList/components/FlightListInfo/component";
import { ErrorCode } from "@b2bportal/air-price-watch-api";
import {
  isCfarMulticityEnabledSelector,
  isThebesHackerFaresV2Cap1ExperimentAvailable,
} from "./experiments";
import { isCorpTenant } from "@capone/common";
import { config } from "../../../../api/config";
import {
  AVAILABLE,
  CONTROL,
  CORP_HIDE_PRICE_DROP_EXPERIMENT,
  HOTEL_CROSS_SELL_V3_EXPERIMENT,
} from "../../../../context/experiments";
import { getPotentialCrossSellOffers } from "../../../cross-sell/reducer/selectors";

// State selectors

export const getWatches = (state: IStoreState) => state.flightShop.watches;

export const tripSummariesLoadingSelector = (
  state: IStoreState
): boolean | null => state.flightShop.tripSummariesLoading;

export const predictionLoadingSelector = (state: IStoreState): boolean | null =>
  state.flightShop.predictionLoading;

export const tripSummariesErrorSelector = (
  state: IStoreState
): boolean | null => state.flightShop.tripSummariesError;

export const tripSummariesErrorCodeSelector = (
  state: IStoreState
): string | null => state.flightShop.tripSummariesErrorCode;

export const allTripSummariesSelector = (
  state: IStoreState
): ITripSummariesById => state.flightShop.tripSummariesById;

export const refreshPredictionSelector = (state: IStoreState) =>
  state.flightShop.rerunPrediction;

export const predictionSelector = (state: IStoreState): Prediction | null => {
  if (
    state.flightShop.experiments?.[CORP_HIDE_PRICE_DROP_EXPERIMENT] ===
    AVAILABLE
  ) {
    return {
      ...state.flightShop.prediction,
      priceDropProtection: {
        ...state.flightShop.prediction?.priceDropProtection,
        PriceDropProtection: PriceDropProtectionEnum.NotEligible,
      },
    } as Prediction;
  }
  return state.flightShop.prediction;
};
export const predictionErrorSelector = (state: IStoreState): boolean =>
  state.flightShop.predictionError;

export const selectedMulticityTripsSelector = (
  state: IStoreState
): ISelectedMulticityTrip[] => state.flightShop.selectedMulticityTrips;

export const selectedTripSelector = (
  state: IStoreState
): ISelectedTrip | ISelectedMulticityTrip => state.flightShop.selectedTrip;

export const selectedTripIdSelector = (state: IStoreState): string | null =>
  state.flightShop.selectedTrip.tripId;

export const returnFlightsByOutgoingIdSelector = (
  state: IStoreState
): IReturnSlicesByOutgoingId => state.flightShop.returnFlightsByOutgoingId;

export const flightShopProgressSelector = (state: IStoreState) =>
  state.flightShop.progress;

export const flightShopMulticityProgressSelector = (state: IStoreState) =>
  state.flightShop.multicityProgress;

export const flightsSelector = (state: IStoreState): Flights | null =>
  state.flightShop.flights;

export const flightSlicesSelector = createSelector(
  flightsSelector,
  (flights) => flights?.slices
);

export const flighFaresSelector = createSelector(
  flightsSelector,
  (flights) => flights?.fares
);

export const getPriceFreezeOfferWithSuggested = (state: IStoreState) =>
  state.flightShop.priceFreezeOffer;

export const getPriceFreezeCustomOffer = (state: IStoreState) =>
  state.flightFreeze.customPriceFreezeOffer;

export const flightShopTypeSelector = (state: IStoreState) =>
  state.flightShop.flightShopType;

export const flightShopProductRedeemChoice = (state: IStoreState) =>
  state.flightShop.productRedeemChoice;

// Corporate Travel
export const getCorporateTravel = (state: IStoreState) => {
  const selectedTrip = state.flightShop.selectedTrip;
  const tripId = selectedTrip.tripId;
  if (tripId) {
    const tripDetails = tripDetailsSelector(state, tripId);
    const selectedFareId =
      (selectedTrip as ISelectedTrip).returnFareId ??
      (selectedTrip as ISelectedTrip).outgoingFareId ??
      (selectedTrip as ISelectedMulticityTrip).departureFareId;

    const fareDetails = tripDetails?.fareDetails.find(
      (f) => f.id === selectedFareId
    );

    return fareDetails && "corporateTravel" in fareDetails
      ? fareDetails.corporateTravel
      : undefined;
  } else {
    return undefined;
  }
};

export const getCorporateTravelProperties = createSelector(
  getCorporateTravel,
  (corporateTravel) => {
    const isInPolicy = corporateTravel?.policyCompliance.isInPolicy;

    return {
      in_policy: isInPolicy,
      ...(!isInPolicy && {
        policy_reason: corporateTravel?.policyCompliance?.reasons.join(", "),
      }),
    };
  }
);

const selectedFlightsFromPriceFreezePurchase = (
  flights: Flights | null
): Flights | null => {
  if (flights) {
    Object.keys(flights.slices).forEach((sliceId) => {
      if (
        filters.performIsSouthwestAirlines(flights.slices[sliceId]) ||
        filters.doesFlightHaveFilteredAirlines(flights.slices[sliceId], ["UA"])
      ) {
        delete flights.slices[sliceId];
      }
    });
    delete flights.airlines["WN"];
    delete flights.airlines["UA"];
  }
  return flights;
};

export const multicityFlightsSelector = (state: IStoreState) =>
  state.flightShop.multicityFlights;

export const flightsByFlightShopTypeSelector = createSelector(
  getTripCategory,
  multicityFlightsSelector,
  flightsSelector,
  selectedFlightsFromRebookSummarySelector,
  flightShopTypeSelector,
  (
    tripCategory,
    multicityFlights,
    flights,
    flightsFromRebookSummary,
    flightShopType
  ): MulticityFlights | Flights | null => {
    if (tripCategory === TripCategory.MULTI_CITY) return multicityFlights;
    switch (flightShopType) {
      case FlightShopType.DISRUPTION_PROTECTION_EXERCISE: {
        return flightsFromRebookSummary;
      }
      case FlightShopType.PRICE_FREEZE_PURCHASE: {
        return selectedFlightsFromPriceFreezePurchase(flights);
      }
      default: {
        return flights;
      }
    }
  }
);

export const similarFlightsResponseSelector = (state: IStoreState) =>
  state.flightShop.similarFlightsResponse;

export const fetchSimilarFlightsCallStateSelector = (state: IStoreState) =>
  state.flightShop.fetchSimilarFlightsCallState;

export const transferToSimilarFlightsResponseSelector = (state: IStoreState) =>
  state.flightShop.transferToSimilarFlightsResponse;

export const fetchTransferToSimilarFlightsCallStateSelector = (
  state: IStoreState
) => state.flightShop.fetchTransferToSimilarFlightsCallState;

export const ancillaryOfferResponseSelector = (state: IStoreState) =>
  state.flightShop.ancillaryOfferResponse;

export const ancillaryOfferSelector = createSelector(
  ancillaryOfferResponseSelector,
  (ancillaryOfferResponse) => ancillaryOfferResponse?.ancillaryOffers
);
export const hasSelectedRefundableFareSelector = (state: IStoreState) =>
  state.flightShop.hasSelectedRefundableFare;

export const refundableFaresPropertiesSelector = (state: IStoreState) =>
  state.flightShop.refundableFaresProperties;

export const getShopRequest = (state: IStoreState) => {
  return state.flightShop.shopRequest;
};

export const getShopExperiments = (state: IStoreState) =>
  state.flightShop.experiments;

export const cfarOfferFromRefundableFaresSelector = createSelector(
  hasSelectedRefundableFareSelector,
  isRefundableFaresEnabledSelector,
  selectedTripSelector,
  cfarOffersSelector,
  (
    hasSelectedRefundableFare,
    isRefundableFaresEnabled,
    selectedTrip,
    cfarOffers
  ): CfarOffer | undefined => {
    const selectedOWRTrip = selectedTrip as ISelectedTrip;
    const tripId = selectedOWRTrip.tripId;
    const fareId =
      selectedOWRTrip?.returnFareId ?? selectedOWRTrip.outgoingFareId;

    if (
      hasSelectedRefundableFare &&
      isRefundableFaresEnabled &&
      !isEmpty(cfarOffers) &&
      tripId &&
      fareId
    ) {
      return (cfarOffers[tripId]?.[fareId] as FetchCfarOfferSuccessV2)
        ?.cfarOffer;
    }

    return undefined;
  }
);

export const cfarOfferSelector = createSelector(
  ancillaryOfferSelector,
  cfarOfferFromRefundableFaresSelector,
  (ancillaryOffer, cfarOfferFromRefundableFares): CfarOffer[] | undefined => {
    if (cfarOfferFromRefundableFares) {
      return [cfarOfferFromRefundableFares];
    } else if (ancillaryOffer) {
      return ancillaryOffer.filter(
        (offer) =>
          offer.AncillaryOffer === undefined ||
          offer.AncillaryOffer === AncillaryOfferEnum.CfarOffer
      ) as CfarOffer[];
    }

    return undefined;
  }
);

export const chfarOffersSelector = createSelector(
  ancillaryOfferSelector,
  (ancilliaryOffer): AirChfarOfferV1[] | undefined => {
    if (ancilliaryOffer) {
      return ancilliaryOffer.filter(
        (offer) => offer.AncillaryOffer === AncillaryOfferEnum.ChfarOffer
      ) as AirChfarOfferV1[];
    }
    return undefined;
  }
);

export const currentCfarChangePolicySelector = createSelector(
  ancillaryOfferResponseSelector,
  (ancillaryOfferResponse): UtasPolicy[] =>
    getCfarChangePolicyFromAncillaryOfferResponse(ancillaryOfferResponse)
);

export const hasCfarOfferSelector = createSelector(
  cfarOfferSelector,
  currentCfarChangePolicySelector,
  (cfarOffer, changePolicy) => {
    return (
      cfarOffer &&
      cfarOffer.length > 0 &&
      // We treat changePolicy as a prerequisite for CFAR offering.
      // If change policy does not exist, we proceed as if CFAR offer also does not exist.
      changePolicy.length > 0
    );
  }
);

export const disruptionProtectionOfferSelector = createSelector(
  ancillaryOfferSelector,
  (ancillaryOffer): (MissedConnectionOffer | DelayOffer)[] | undefined => {
    if (ancillaryOffer) {
      return ancillaryOffer.filter(
        (offer) =>
          offer.AncillaryOffer === AncillaryOfferEnum.MissedConnectionOffer ||
          offer.AncillaryOffer === AncillaryOfferEnum.DelayOffer
      ) as (MissedConnectionOffer | DelayOffer)[];
    }

    return undefined;
  }
);

export const hasDisruptionProtectionOfferSelector = createSelector(
  disruptionProtectionOfferSelector,
  (disruptionProtectionOffer) => {
    return disruptionProtectionOffer && disruptionProtectionOffer.length > 0;
  }
);

export const fetchAncillaryOfferCallStateSelector = (state: IStoreState) =>
  state.flightShop.fetchAncillaryOfferCallState;

export const selectedCfarIdSelector = (state: IStoreState) =>
  state.flightShop.selectedCfarId;

export const selectedChfarIdSelector = (state: IStoreState) =>
  state.flightShop.selectedChfarId;

export const selectedDisruptionProtectionIdSelector = (state: IStoreState) =>
  state.flightShop.selectedDisruptionProtectionId;

export const tripDetailsByIdSelector = (
  state: IStoreState
): ITripDetailsByTripId => {
  return state.flightShop.tripDetailsById;
};

export const selectedTripDetailsSelector = createSelector(
  tripDetailsByIdSelector,
  selectedTripSelector,
  (tripDetails, selectedTrip): TripDetails | null => {
    if (selectedTrip?.tripId) {
      return tripDetails[selectedTrip.tripId];
    } else {
      return null;
    }
  }
);

export const isSelectedTripMarketedBySpecificAirlineSelector = (
  state: IStoreState,
  airlineCode: string
) => {
  const selectedTripDetails = selectedTripDetailsSelector(state);

  return selectedTripDetails?.slices.some((slice) =>
    slice.segmentDetails.some(
      (segment) => segment.marketingAirline.code === airlineCode
    )
  );
};

export const selectedFareDetailsSelector = createSelector(
  getTripCategory,
  selectedTripDetailsSelector,
  selectedTripSelector,
  (
    tripCategory,
    selectedTripDetails,
    selectedTrip
  ): FareDetails | CorpFareDetails | undefined => {
    let fareId = "";
    if (tripCategory === TripCategory.MULTI_CITY) {
      const selectedMcTrip = selectedTrip as ISelectedMulticityTrip;
      fareId = selectedMcTrip.departureFareId as string;
    } else {
      const selectedOWRTrip = selectedTrip as ISelectedTrip;
      fareId = selectedOWRTrip?.returnFareId
        ? (selectedOWRTrip.returnFareId as string)
        : (selectedOWRTrip.outgoingFareId as string);
    }
    return selectedTripDetails?.fareDetails.find((fare) => fare.id == fareId);
  }
);

export const hasPriceFreezeOnOutboundSelector = (state: IStoreState) =>
  state.flightShop.hasPriceFreezeOnOutbound;

export const isFlightShopLoadingTripSummaries = createSelector(
  tripSummariesLoadingSelector,
  fetchSimilarFlightsCallStateSelector,
  rebookSummaryCallStateSelector,
  flightShopTypeSelector,
  (
    tripSummariesLoading,
    fetchSimilarFlightsCallState,
    rebookSummaryCallState,
    flightShopType
  ): boolean => {
    switch (flightShopType) {
      case FlightShopType.DISRUPTION_PROTECTION_EXERCISE:
        return rebookSummaryCallState === CallState.InProcess;
      case FlightShopType.PRICE_FREEZE_EXERCISE:
        return fetchSimilarFlightsCallState === CallState.InProcess;
      case FlightShopType.PRICE_FREEZE_PURCHASE:
      case FlightShopType.DEFAULT:
      default:
        return tripSummariesLoading ?? tripSummariesLoading === null;
    }
  }
);

export const priceDropProtectionCandidateIdSelector = createSelector(
  predictionSelector,
  (prediction) => {
    const priceDropProtection = prediction?.priceDropProtection;
    switch (priceDropProtection?.PriceDropProtection) {
      case PriceDropProtectionEnum.IsEligible:
        return priceDropProtection.candidateId;
      default:
        return undefined;
    }
  }
);

export const getPriceDropProperties = createSelector(
  predictionSelector,
  getPriceDropRefundTypeSelector,
  (
    prediction,
    priceDropRefundType
  ): Omit<PriceDropViewedProperties, "page"> => {
    const eligible =
      prediction?.priceDropProtection?.PriceDropProtection ===
      PriceDropProtectionEnum.IsEligible;
    return {
      price_drop_offer_duration:
        eligible && prediction
          ? (prediction?.priceDropProtection as IsEligible).monitoringDuration
              .inSeconds
          : 0,
      price_drop_offer_max_cap:
        eligible && prediction
          ? (prediction?.priceDropProtection as IsEligible).maximumRefund.amount
              .amount
          : 0,
      price_drop_offer_min_cap:
        eligible && prediction
          ? (prediction?.priceDropProtection as IsEligible).minimumRefund.amount
              .amount
          : 0,
      price_bucket: prediction
        ? getPriceBucketValueFromDealness(prediction.dealness)
        : null,
      refund_type: eligible ? priceDropRefundType : null,
    };
  }
);

export const isPriceFreezeOfferIncludedInShopSummarySelector = createSelector(
  getPriceFreezeOfferWithSuggested,
  (priceFreezeOffer) => !!priceFreezeOffer
);

export const isSelectReturnReadySelector = createSelector(
  selectedTripSelector,
  getTripCategory,
  (selectedTrip, tripCategory): boolean => {
    const selectedOWRTrip = selectedTrip as ISelectedTrip;
    const isDepartureFlightSelected =
      !!selectedOWRTrip.outgoingFareId && !!selectedOWRTrip.outgoingSliceId;
    return (
      isDepartureFlightSelected && tripCategory === TripCategory.ROUND_TRIP
    );
  }
);

export const isFlightReviewReadySelector = createSelector(
  getTripCategory,
  selectedTripSelector,
  getMulticityRoutes,
  selectedMulticityTripsSelector,
  (
    tripCategory,
    selectedTrip,
    searchedMulticityRoutes,
    selectedMulticityTrips
  ): boolean => {
    if (tripCategory === TripCategory.MULTI_CITY) {
      const selectedMcTrip = selectedTrip as ISelectedMulticityTrip;
      const numSelectedSlices = selectedMulticityTrips.length;
      const lastSelectedSlice = selectedMulticityTrips[numSelectedSlices - 1];
      return (
        searchedMulticityRoutes.length === numSelectedSlices &&
        selectedMcTrip.departureFareId === lastSelectedSlice.departureFareId
      );
    } else {
      const selectedOWRTrip = selectedTrip as ISelectedTrip;
      const isDepartureFlightSelected =
        !!selectedOWRTrip.outgoingFareId && !!selectedOWRTrip.outgoingSliceId;
      const isReturnFlightSelected =
        !!selectedOWRTrip.returnFareId && !!selectedOWRTrip.returnSliceId;

      return (
        isDepartureFlightSelected &&
        (tripCategory === TripCategory.ONE_WAY || isReturnFlightSelected)
      );
    }
  }
);

export const isCfarAvailableSelector = createSelector(
  isCfarEnabledSelector,
  cfarOfferSelector,
  currentCfarChangePolicySelector,
  fetchAncillaryOfferCallStateSelector,
  hasSelectedRefundableFareSelector,
  isRefundableFaresEnabledSelector,
  (
    isCfarEnabled,
    cfarOffer,
    changePolicy,
    fetchAncillaryOfferCallState,
    hasSelectedRefundableFare,
    isRefundableFaresEnabled
  ): boolean => {
    return (
      isCfarEnabled &&
      fetchAncillaryOfferCallState === CallState.Success &&
      !!cfarOffer &&
      cfarOffer.length > 0 &&
      !!changePolicy &&
      changePolicy.length > 0 &&
      !(hasSelectedRefundableFare && isRefundableFaresEnabled)
    );
  }
);

export const isChfarAvailableSelector = createSelector(
  isChfarEnabledSelector,
  chfarOffersSelector,
  fetchAncillaryOfferCallStateSelector,
  (isChfarEnabled, chfarOffers, fetchAncillaryOfferCallState) => {
    return (
      isChfarEnabled &&
      !!chfarOffers &&
      chfarOffers.length > 0 &&
      fetchAncillaryOfferCallState === CallState.Success
    );
  }
);

export const isDisruptionProtectionAvailableSelector = createSelector(
  isDisruptionProtectionEnabledSelector,
  disruptionProtectionOfferSelector,
  fetchAncillaryOfferCallStateSelector,
  (
    isDisruptionProtectionEnabled,
    disruptionProtectionOffer,
    fetchAncillaryOfferCallState
  ): boolean => {
    return (
      isDisruptionProtectionEnabled &&
      fetchAncillaryOfferCallState === CallState.Success &&
      !!disruptionProtectionOffer &&
      disruptionProtectionOffer.length > 0
    );
  }
);

export const useGroupedAncillariesSelector = createSelector(
  isDisruptionProtectionAvailableSelector,
  isChfarAvailableSelector,
  isCfarAvailableSelector,
  (isDisruptionAvailable, isChfarAvailable, isCfarAvailable) =>
    isDisruptionAvailable && isChfarAvailable && isCfarAvailable
);

export const selectedCfarOfferPricesSelector = createSelector(
  selectedCfarIdSelector,
  cfarOfferSelector,
  (selectedCfarId, cfarOffer): Prices | undefined => {
    return cfarOffer?.find(
      (cfar) =>
        cfar.id.policyId === selectedCfarId?.policyId &&
        cfar.id.productId === selectedCfarId?.productId
    )?.premiumPerPax;
  }
);

export const selectedCfarOfferCoverageSelector = createSelector(
  selectedCfarIdSelector,
  cfarOfferSelector,
  (selectedCfarId, cfarOffer): CfarOfferPolicyData | undefined => {
    return cfarOffer?.find(
      (cfar) =>
        cfar.id.policyId === selectedCfarId?.policyId &&
        cfar.id.productId === selectedCfarId?.productId
    )?.policyData;
  }
);

export const selectedDiscountedCfarOfferPricesSelector = createSelector(
  selectedCfarIdSelector,
  cfarOfferSelector,
  (selectedCfarId, cfarOffer): CfarDiscount | undefined => {
    return cfarOffer?.find(
      (cfar) =>
        cfar.id.policyId === selectedCfarId?.policyId &&
        cfar.id.productId === selectedCfarId.productId
    )?.discount;
  }
);

export const selectedChfarOfferPricesSelector = createSelector(
  selectedChfarIdSelector,
  chfarOffersSelector,
  (selectedChfarId, chfarOffers): AirChfarOfferPrices | undefined => {
    return chfarOffers?.find(
      (offer) => offer.quoteId === selectedChfarId?.quoteId
    )?.premiumAmount;
  }
);

export const selectedDisruptionProtectionOfferSelector = createSelector(
  selectedDisruptionProtectionIdSelector,
  disruptionProtectionOfferSelector,
  (
    selectedDisruptionProtectionId,
    disruptionProtectionOffer
  ): DelayOffer | MissedConnectionOffer | undefined => {
    if (
      selectedDisruptionProtectionId?.productId ===
        CORP_FINTECH_SUBSCRIPTION_KEY &&
      disruptionProtectionOffer
    ) {
      return disruptionProtectionOffer[0];
    }

    return disruptionProtectionOffer?.find(
      (offer) =>
        offer.id.policyId === selectedDisruptionProtectionId?.policyId &&
        offer.id.productId === selectedDisruptionProtectionId?.productId
    );
  }
);

export const selectedDisruptionProtectionOfferPricesSelector = createSelector(
  selectedDisruptionProtectionOfferSelector,
  (selectedDisruptionProtectionOffer): Prices | undefined => {
    return selectedDisruptionProtectionOffer?.premiumPricing.perPassengerPrices;
  }
);

export const selectedDisruptionProtectionOfferDelayThresholdSelector =
  createSelector(
    selectedDisruptionProtectionOfferSelector,
    (selectedDisruptionProtectionOffer): number | undefined => {
      return selectedDisruptionProtectionOffer?.extraProperties?.policyDetails
        ?.minDelayMinutes;
    }
  );

export const isAddOnOptionAvailableSelector = createSelector(
  isCfarAvailableSelector,
  isDisruptionProtectionAvailableSelector,
  isChfarAvailableSelector,
  getTripCategory,
  isCfarMulticityEnabledSelector,
  (
    isCfarAvailable,
    isDisruptionProtectionAvailable,
    isChfarAvailable,
    tripCategory,
    isCfarMulticityEnabled
  ): boolean => {
    const isMulticity = tripCategory === TripCategory.MULTI_CITY;
    return (
      ((!isMulticity || isCfarMulticityEnabled) && isCfarAvailable) ||
      isDisruptionProtectionAvailable ||
      isChfarAvailable
    );
  }
);

export const isFlightBookWithAncillariesActiveSelector = createSelector(
  isFlightBookWithAncillariesEnabledSelector,
  isAddOnOptionAvailableSelector,
  (isFlightBookWithAncillariesEnabled, isAddOnOptionAvailable) =>
    isFlightBookWithAncillariesEnabled && isAddOnOptionAvailable
);

export const hasSelectedCfarOptionSelector = createSelector(
  selectedCfarIdSelector,
  isCfarAvailableSelector,
  (selectedCfarId, isCfarAvailable): boolean => {
    return !isCfarAvailable || !!selectedCfarId;
  }
);

export const hasSelectedDisruptionProtectionOptionSelector = createSelector(
  selectedDisruptionProtectionIdSelector,
  isDisruptionProtectionAvailableSelector,
  (
    selectedDisruptionProtectionId,
    isDisruptionProtectionAvailable
  ): boolean => {
    return !isDisruptionProtectionAvailable || !!selectedDisruptionProtectionId;
  }
);

export const isCfarOptionSelectionCompleteSelector = createSelector(
  isCfarAvailableSelector,
  hasSelectedCfarOptionSelector,
  isCfarEnabledSelector,
  (isCfarAvailable, hasSelectedCfarOption, isCfarEnabled): boolean => {
    return !isCfarEnabled || !isCfarAvailable || hasSelectedCfarOption;
  }
);

export const isDpOptionSelectionCompleteSelector = createSelector(
  isDisruptionProtectionAvailableSelector,
  hasSelectedDisruptionProtectionOptionSelector,
  isDisruptionProtectionEnabledSelector,
  (
    isDisruptionProtectionAvailable,
    hasSelectedDisruptionProtectionOption,
    isDisruptionProtectionEnabled
  ): boolean => {
    return (
      !isDisruptionProtectionEnabled ||
      !isDisruptionProtectionAvailable ||
      hasSelectedDisruptionProtectionOption
    );
  }
);

export const isOptionSelectionCompleteSelector = createSelector(
  isCfarOptionSelectionCompleteSelector,
  isDpOptionSelectionCompleteSelector,
  (isCfarOptionSelectionComplete, isDpOptionSelectionComplete): boolean => {
    return isCfarOptionSelectionComplete && isDpOptionSelectionComplete;
  }
);

export const isRefundableFaresActiveInFlightShopSelector = createSelector(
  flightShopTypeSelector,
  isRefundableFaresEnabledSelector,
  (flightShopType, isRefundableFaresEnabled): boolean => {
    return (
      flightShopType === FlightShopType.DEFAULT && isRefundableFaresEnabled
    );
  }
);

// note: it's for implementing this rule https://hopper-jira.atlassian.net/browse/BF-1013
export const showNoFtcOnlyInRefundableFaresSelector = createSelector(
  getTripCategory,
  flightShopProgressSelector,
  isRefundableFaresActiveInFlightShopSelector,
  (
    tripCategory,
    flightShopProgress,
    isRefundableFaresActiveInFlightShop
  ): boolean => {
    return (
      tripCategory === TripCategory.ROUND_TRIP &&
      flightShopProgress === FlightShopStep.ChooseDeparture &&
      isRefundableFaresActiveInFlightShop
    );
  }
);

export const hasActiveRefundableFareInFlightShopSelector = createSelector(
  selectedCfarIdSelector,
  hasSelectedRefundableFareSelector,
  isRefundableFaresActiveInFlightShopSelector,
  (
    selectedCfarId,
    hasSelectedRefundableFare,
    isRefundableFaresActiveInFlightShop
  ): boolean =>
    !!selectedCfarId &&
    selectedCfarId.policyId !== DO_NOT_APPLY_OPTION_KEY &&
    hasSelectedRefundableFare &&
    isRefundableFaresActiveInFlightShop
);

export const perPaxRefundableFarePricesSelector = createSelector(
  selectedFareDetailsSelector,
  selectedCfarOfferPricesSelector,
  hasActiveRefundableFareInFlightShopSelector,
  (
    fareDetails,
    cfarOfferPrices,
    hasActiveRefundableFareInFlightShop
  ): Prices | undefined => {
    const basePricing = fareDetails?.paxPricings?.[0]?.pricing;

    if (!basePricing) {
      return undefined;
    }

    const baseTotal =
      basePricing.total ??
      getAddedLegacyPrices(basePricing.baseAmount, basePricing.taxAmount);

    if (hasActiveRefundableFareInFlightShop && !!cfarOfferPrices) {
      return getAddedLegacyPrices(baseTotal, cfarOfferPrices);
    } else {
      return baseTotal;
    }
  }
);

export const isInDisruptionProtectionRebookSelector = createSelector(
  flightShopTypeSelector,
  (flightShopType): boolean =>
    flightShopType === FlightShopType.DISRUPTION_PROTECTION_EXERCISE
);

export const isPartiallyRebookingSelector = createSelector(
  isInDisruptionProtectionRebookSelector,
  disruptionProtectionOriginalSliceSelector,
  getOrigin,
  (isInDisruptionProtectionRebook, originalSlice, origin): boolean => {
    return (
      isInDisruptionProtectionRebook &&
      !!originalSlice &&
      originalSlice.segments.length > 0 &&
      originalSlice.segments[0].origin.locationCode !== origin?.id.code.code
    );
  }
);

export const isPartiallyRebookingNotificationPropsSelector = createSelector(
  isPartiallyRebookingSelector,
  disruptionProtectionOriginalSliceContextSelector,
  getOrigin,
  getDestination,
  (
    isPartiallyRebooking,
    originalSliceContext,
    origin,
    destination
  ):
    | {
        isTarget: (segment: TripSegment) => boolean;
        message: string;
      }
    | undefined => {
    if (isPartiallyRebooking) {
      return {
        isTarget: (segment: TripSegment) =>
          segment.originCode === origin?.id.code.code,
        message: PARTIALLY_REBOOKING_TEXT({
          origin: origin?.id.code.code,
          destination: destination?.id.code.code,
          airports: originalSliceContext?.airports,
        }),
      };
    }

    return undefined;
  }
);

export const hasSelectedLowerFareClassForRebookSelector = createSelector(
  disruptionProtectionOriginalSliceSelector,
  selectedFareDetailsSelector,
  (originalSlice, selectedFareDetails): boolean => {
    const originalFareRating = originalSlice?.fareShelf?.rating ?? 0;
    // note: slice[0] is the slice containing the departure flight
    const newFareRating =
      selectedFareDetails?.slices[0]?.fareShelf?.rating ?? 0;

    return newFareRating < originalFareRating;
  }
);

export const selectedSliceFlightSummaryPropsForRebookSelector = createSelector(
  selectedTripSelector,
  selectedTripDetailsSelector,
  selectedFareDetailsSelector,
  flightsByFlightShopTypeSelector,
  (
    selectedTrip,
    selectedTripDetails,
    selectedFareDetails,
    flights
  ): IFlightSummaryData | undefined => {
    // note: even if the original disrupted slice is the return slice, we are rebooking a new one-way (departure) flight
    const selectedOWRTrip = selectedTrip as ISelectedTrip;
    const sliceId = selectedOWRTrip.outgoingSliceId;
    const tripSlice = selectedTripDetails?.slices.find(
      (slice) => slice.id === sliceId
    );
    const fareSlice = selectedFareDetails?.slices[0];

    if (!tripSlice || !fareSlice) {
      return undefined;
    }

    const segments = tripSlice.segmentDetails;
    const airlineCode = segments[0]?.airlineCode;
    const airlineName = flights?.airlines[airlineCode]?.displayName ?? "";
    const departureTime = tripSlice.departureTime;
    const arrivalTime = tripSlice.arrivalTime;
    const originCode = tripSlice.originCode;
    const originName = flights?.airports[tripSlice.originCode]?.cityName ?? "";
    const destinationCode = tripSlice.destinationCode;
    const destinationName =
      flights?.airports[tripSlice.destinationCode]?.cityName ?? "";
    const stops = tripSlice.stops;
    const plusDays = getPlusDaysFromItinerarySegments(segments);

    const fareShelf = fareSlice.fareShelf;
    const fareSegments = fareSlice.fareDetails.segments;
    const cabinClassNames = fareSegments.map(
      (segment) => segment.cabinClassName
    );

    return {
      fareShelfRating: fareShelf?.rating ?? 0,
      fareShelfBrandName: fareShelf?.brandName ?? "",
      fareShelfShortBrandName: fareShelf?.shortBrandName ?? "",
      tripSlice: {
        segments,
        cabinClassNames,
        airlineCode,
        airlineName,
        departureTime,
        arrivalTime,
        originName,
        originCode,
        destinationName,
        destinationCode,
        stops,
        plusDays,
      },
    };
  }
);

export const watchesSelector = (state: IStoreState): WatchAlertView[] =>
  state.flightShop.watches;

export const openFlightShopCalendarDesktopSelector = (
  state: IStoreState
): boolean => state.flightShop.openFlightShopCalendarDesktop;

export const openFlightShopCalendarMobileSelector = (
  state: IStoreState
): boolean => state.flightShop.openFlightShopCalendarMobile;

export const createWatchCallStateSelector = (state: IStoreState): CallState =>
  state.flightShop.createWatchCallState;

export const createWatchFailureCodesSelector = (
  state: IStoreState
): ErrorCode[] | null => state.flightShop.createWatchFailureCodes;

export const listWatchCallStateSelector = (state: IStoreState): CallState =>
  state.flightShop.listWatchCallState;

export const updateWatchCallStateSelector = (state: IStoreState): CallState =>
  state.flightShop.updateWatchCallState;

export const deleteWatchCallStateSelector = (state: IStoreState): CallState =>
  state.flightShop.deleteWatchCallState;

export const getSelectedFlightIndex = (state: IStoreState): number | null =>
  state.flightShop.selectedFlightIndex;

export const getBestOfferOverall = (
  state: IStoreState
): TravelWalletOffer | undefined => state.flightShop.bestOfferOverall;

export const getOffersByTripId = (
  state: IStoreState
): { [key: string]: TravelWalletOffer } | undefined =>
  state.flightShop.offersByTripId;

export const tripIdsByReturnSliceSelector = createSelector(
  selectedTripSelector,
  returnFlightsByOutgoingIdSelector,
  (
    selectedTrip,
    returnFlightsByOutgoingIdMap
  ): ITripIdsByReturnSlice | null => {
    const selectedOWRTrip = selectedTrip as ISelectedTrip;
    if (selectedTrip && selectedOWRTrip.outgoingSliceId) {
      return returnFlightsByOutgoingIdMap[selectedOWRTrip.outgoingSliceId];
    } else {
      return null;
    }
  }
);

export const tripSummariesByIdSelector = createSelector(
  selectedTripSelector,
  flightShopProgressSelector,
  allTripSummariesSelector,
  (selectedTrip, shopStep, tripSummaries): ITripSummariesById => {
    const selectedOWRTrip = selectedTrip as ISelectedTrip;
    // return valid return slices for a selected outgoing slice
    if (
      shopStep === FlightShopStep.ChooseReturn &&
      selectedOWRTrip.outgoingSliceId
    ) {
      const returnTrips = {};
      const outgoingSliceId = selectedOWRTrip.outgoingSliceId;
      const outgoingFareRating = selectedOWRTrip.outgoingFareRating;
      Object.values(tripSummaries).forEach((summary) => {
        const hasValidFares = summary.tripFares.some(
          (f) => f.fareShelf?.outgoing?.rating === outgoingFareRating
        );
        if (
          summary.outgoingSlice.id === outgoingSliceId &&
          (!outgoingFareRating || hasValidFares)
        ) {
          returnTrips[summary.tripId] = summary;
        }
      });
      return returnTrips;
    } else {
      // de-dupe trip summaries by unique outgoing slices
      const outgoingIds = new Set();
      const outgoingTrips = {};
      Object.values(tripSummaries).forEach((summary) => {
        if (!outgoingIds.has(summary.outgoingSlice.id)) {
          outgoingIds.add(summary.outgoingSlice.id);
          outgoingTrips[summary.tripId] = summary;
        }
      });
      return outgoingTrips;
    }
  }
);

export const tripSliceKeySelector = createSelector(
  flightShopProgressSelector,
  (flightShopProgress): string =>
    flightShopProgress === FlightShopStep.ChooseReturn
      ? "returningSlice"
      : "outgoingSlice"
);

const tripSummariesSelector = createSelector(
  tripSummariesByIdSelector,
  (tripSummaries): TripSummary[] => Object.values(tripSummaries)
);

export const flightInfoSelector = (
  state: IStoreState,
  tripId: string
): TripSummary => state.flightShop.tripSummariesById[tripId];

export const flightFareDetailsSelector = (
  state: IStoreState,
  tripId: string
): TripSummary["tripFares"] =>
  state.flightShop.tripSummariesById[tripId]
    ? state.flightShop.tripSummariesById[tripId].tripFares
    : [];

export const tripDetailsLoadingSelector = (
  state: IStoreState
): boolean | null => state.flightShop.tripDetailsLoading;

export const tripDetailsSelector = (
  state: IStoreState,
  tripId: string
): TripDetails => {
  return state.flightShop.tripDetailsById[tripId];
};

export const tripSummarySelector = (
  state: IStoreState,
  tripId: string
): TripSummary => {
  // TODO: Do not fallback here... This is to improve mock data experience.
  return (
    state.flightShop.tripSummariesById[tripId] ||
    Object.values(state.flightShop.tripSummariesById)[0]
  );
};

export const airportsSelector = (state: IStoreState, tripId: string) => {
  const tripSummaries = tripSummariesByIdSelector(state);
  const flights =
    flightsByFlightShopTypeSelector(state) || multicityFlightsSelector(state);
  const tripSummary = tripSummaries[tripId];
  return tripSummary
    ? tripSummary.context.airports
    : flights
    ? flights.airports
    : {};
};

export const airlinesSelector = (state: IStoreState, tripId: string) => {
  const tripSummaries = tripSummariesByIdSelector(state);
  const flights = flightsByFlightShopTypeSelector(state);
  const tripSummary = tripSummaries[tripId];
  return tripSummary
    ? tripSummary.context.airlines
    : flights
    ? flights.airlines
    : {};
};

export const selectedSliceFareDetailsSelector = (
  state: IStoreState,
  tripId: string,
  fareId: string
): FareDetails | undefined =>
  state.flightShop.tripDetailsById[tripId].fareDetails.find(
    (fare) => fare.id === fareId
  );

export const sortOptionSelector = (state: IStoreState): FlightSortOption =>
  state.flightShop.sortOption;

// Max Price Filter
// Note: these values are the same regardless of our progress
export const maxFlightPriceSelector = createSelector(
  allTripSummariesSelector,
  (flights): number => {
    let max = 0;
    Object.values(flights).forEach((flight) => {
      flight.tripFares?.forEach((fare) => {
        max = Math.max(fare.amount?.fiat?.value || 0, max);
      });
    });
    return max;
  }
);
export const minFlightPriceSelector = createSelector(
  allTripSummariesSelector,
  (flights): number => {
    let min = Number.MAX_SAFE_INTEGER;
    Object.values(flights).forEach((flight) => {
      flight.tripFares?.forEach((fare) => {
        min = Math.min(
          fare.amount?.fiat?.value || Number.MAX_SAFE_INTEGER,
          min
        );
      });
    });
    return min;
  }
);

export const hasSetMaxPriceFilterSelector = createSelector(
  getMaxPriceFilter,
  flightShopTypeSelector,
  (maxPriceFilter, flightShopType) => {
    switch (flightShopType) {
      case FlightShopType.DISRUPTION_PROTECTION_EXERCISE: {
        return false;
      }
      default: {
        return maxPriceFilter !== initialFilterOptions.maxPriceFilter;
      }
    }
  }
);

// Airline Filter
export interface IAirlineOptions {
  label: AirlineCode;
  value: AirlineCode;
  icon?: JSX.Element;
}

export const allAirlinesSelector = createSelector(
  tripSummariesSelector,
  tripSliceKeySelector,
  (tripSummaries, tripSliceKey): IAirlineOptions[] => {
    // TODO: switch to airline name + airline code when it is available from the backend
    const airlines = new Set<AirlineCode>();
    const allAirlines: IAirlineOptions[] = [];

    tripSummaries.forEach((flight: TripSummary) =>
      flight[tripSliceKey]!.segmentDetails.forEach((segment: TripSegment) => {
        if (!airlines.has(segment.airlineCode)) {
          allAirlines.push({
            value: segment.airlineCode,
            label: segment.airlineName,
          });
        }
        airlines.add(segment.airlineCode);
      })
    );

    return allAirlines;
  }
);

// Airport Filter
export interface IAirportOptions {
  label: string;
  value: string;
}

export const allAirportsSelector = createSelector(
  tripSummariesSelector,
  tripSliceKeySelector,
  (tripSummaries, tripSliceKey): IAirportOptions[] => {
    const airports = new Set<string>();
    const allAirports: IAirportOptions[] = [];

    tripSummaries.forEach((flight) => {
      const airportCode = flight[tripSliceKey].originCode;
      const airportName = flight[tripSliceKey].originName;
      if (!airports.has(airportCode)) {
        allAirports.push({ value: airportCode, label: airportName });
      }
      airports.add(airportCode);
    });

    return allAirports;
  }
);

export interface IFlightNumbersByAirlineCode {
  [key: string]: string[];
}

// Flight Number Selector
export const flightNumbersByAirlineSelector = createSelector(
  tripSummariesSelector,
  tripSliceKeySelector,
  (tripSummaries, tripSliceKey) => {
    const flightNumbersByAirline: IFlightNumbersByAirlineCode = {};

    tripSummaries.forEach((flight) => {
      const [firstSegment] = flight[tripSliceKey].segmentDetails;
      const { flightNumber } = firstSegment;
      const airlineCode = firstSegment.airlineCode;

      if (!flightNumbersByAirline[airlineCode]) {
        flightNumbersByAirline[airlineCode] = [flightNumber];
      } else {
        flightNumbersByAirline[airlineCode] = uniq([
          ...flightNumbersByAirline[airlineCode],
          flightNumber,
        ]);
      }
    });

    return flightNumbersByAirline;
  }
);

const allFiltersSelector = createSelector(
  getFareclassOptionFilter,
  getStopsOption,
  getMaxPriceFilter,
  getOutboundDepartureTimeRange,
  getOutboundArrivalTimeRange,
  getReturnDepartureTimeRange,
  getReturnArrivalTimeRange,
  getAirlineFilter,
  getAirportFilter,
  getFlightNumberFilter,
  getBaggageTransfersFilter,
  getDurationFilter,
  getPolicyFilter,
  getApplyUserFlightPreferences,
  getUserFlightPreferencesNotAvailable,
  (
    fareclassOptionFilter,
    stopsOption,
    maxPriceFilter,
    outboundDepartureTimeRange,
    outboundArrivalTimeRange,
    returnDepartureTimeRange,
    returnArrivalTimeRange,
    airlineFilter,
    airportFilter,
    flightNumberFilter,
    baggageTransfersFilter,
    durationFilter,
    policyFilter,
    applyUserFlightPreferences,
    userPreferencesNotAvailable
  ): IFilterState => ({
    fareclassOptionFilter,
    stopsOption,
    maxPriceFilter,
    outboundDepartureTimeRange,
    outboundArrivalTimeRange,
    returnDepartureTimeRange,
    returnArrivalTimeRange,
    airlineFilter,
    airportFilter,
    policyFilter,
    flightNumberFilter,
    baggageTransfersFilter,
    durationFilter,
    applyUserFlightPreferences,
    userPreferencesNotAvailable,
  })
);

export const hasSetNonFareclassFiltersSelector = createSelector(
  getHasSetStopsOption,
  getHasSetOutboundTimeRange,
  getHasSetReturnTimeRange,
  getHasSetAirlineFilter,
  getHasSetAirportFilter,
  getHasSetFlightNumberFilter,
  hasSetMaxPriceFilterSelector,
  getHasSetPolicyFilter,
  (...args): boolean => {
    return args.some((arg: boolean) => arg);
  }
);

const filteredFlightsSelector = createSelector(
  // Search Filter Selectors
  allFiltersSelector,

  // Search Filter Applied
  getHasSetStopsOption,
  getHasSetOutboundTimeRange,
  getHasSetReturnTimeRange,
  getHasSetAirlineFilter,
  getHasSetAirportFilter,
  getHasSetPolicyFilter,
  getHasSetFlightNumberFilter,
  hasSetMaxPriceFilterSelector,
  getHasSetFareClassFilter,

  // Shop Selectors
  selectedTripSelector,
  tripSummariesByIdSelector,
  flightShopProgressSelector,
  (
    allFiltersSelector: IFilterState,

    hasSetStopsOption,
    hasSetOutboundTimeRange,
    hasSetReturnTimeRange,
    hasSetAirlineFilter,
    hasSetAirportFilter,
    hasSetPolicyFilter,
    hasSetFlightNumberFilter,
    hasSetMaxPriceFilter,
    hasSetFareClassFilter,

    selectedTrip,
    tripSummariesById: ITripSummariesById,
    shopStep: FlightShopStep
  ): ITripSummariesById => {
    const {
      stopsOption,
      maxPriceFilter,
      outboundDepartureTimeRange,
      outboundArrivalTimeRange,
      returnDepartureTimeRange,
      returnArrivalTimeRange,
      airlineFilter,
      airportFilter,
      policyFilter,
      flightNumberFilter,
      fareclassOptionFilter,
    } = allFiltersSelector;

    const isReturn = shopStep === FlightShopStep.ChooseReturn;
    const tripSliceKey = isReturn ? "returningSlice" : "outgoingSlice";

    const selectedFaresOptionsArray = Object.keys(fareclassOptionFilter).filter(
      (key) => fareclassOptionFilter[key] && key
    );

    const meetsFilterPredicates = (tripSummary: TripSummary) => {
      let meetsConditions = true;

      if (hasSetStopsOption) {
        meetsConditions =
          meetsConditions &&
          filters.performStopOptionFilter(
            tripSummary[tripSliceKey]!,
            stopsOption
          );
      }

      if (hasSetOutboundTimeRange && !isReturn) {
        meetsConditions =
          meetsConditions &&
          filters.performTimeRangeFilter(
            tripSummary[tripSliceKey]!,
            outboundDepartureTimeRange,
            outboundArrivalTimeRange
          );
      }

      if (hasSetReturnTimeRange && isReturn) {
        meetsConditions =
          meetsConditions &&
          filters.performTimeRangeFilter(
            tripSummary[tripSliceKey]!,
            returnDepartureTimeRange,
            returnArrivalTimeRange
          );
      }

      if (hasSetAirlineFilter) {
        meetsConditions =
          meetsConditions &&
          filters.performAirlineFilter(
            tripSummary[tripSliceKey]!,
            airlineFilter
          );
      }

      if (hasSetAirportFilter) {
        meetsConditions =
          meetsConditions &&
          filters.performAirportFilter(
            tripSummary[tripSliceKey]!,
            airportFilter
          );
      }

      if (hasSetFlightNumberFilter) {
        meetsConditions =
          meetsConditions &&
          filters.performFlightNumberFilter(
            tripSummary[tripSliceKey]!,
            flightNumberFilter
          );
      }

      if (hasSetPolicyFilter) {
        meetsConditions =
          meetsConditions &&
          filters.performPolicyFilter(tripSummary[tripSliceKey]!, policyFilter);
      }

      if (hasSetMaxPriceFilter) {
        meetsConditions =
          meetsConditions &&
          filters.performMaxPriceFilter(tripSummary, maxPriceFilter);
      }

      if (hasSetFareClassFilter) {
        const selectedOWRTrip = selectedTrip as ISelectedTrip;
        meetsConditions =
          meetsConditions &&
          filters.performFareClassFilter(
            tripSummary,
            selectedFaresOptionsArray,
            isReturn,
            selectedOWRTrip.outgoingFareRating ?? undefined
          );
      }

      return meetsConditions;
    };

    const filteredTrips = {};
    Object.values(tripSummariesById).forEach((summary) => {
      if (meetsFilterPredicates(summary)) {
        filteredTrips[summary.tripId] = summary;
      }
    });
    return filteredTrips;
  }
);

export const orderedAndFilteredFlightIdsSelector = createSelector(
  filteredFlightsSelector,
  sortOptionSelector,
  tripSliceKeySelector,
  (filteredFlights, sortOption, tripSliceKey): string[] => {
    const tripSummaries = Object.values(filteredFlights);
    switch (sortOption) {
      case "fareScore":
        return sorters.orderByRecommended(tripSummaries);
      case "price":
        return sorters.orderByPrice(tripSummaries);
      case "departureTime":
        return sorters.orderByDepartureTime(tripSummaries, tripSliceKey);
      case "arrivalTime":
        return sorters.orderByArrivalTime(tripSummaries, tripSliceKey);
      case "stops":
        return sorters.orderByStops(tripSummaries, tripSliceKey);
      case "duration":
        return sorters.orderByDuration(tripSummaries, tripSliceKey);

      default:
        return sorters.orderByRecommended(tripSummaries);
    }
  }
);

export const shopPricingInfoSelector = (state: IStoreState) => {
  const selectedTrip = state.flightShop.selectedTrip as ISelectedTrip;
  const tripId = selectedTrip.tripId || "";
  const tripDetails = tripDetailsSelector(state, tripId);
  if (!tripDetails) {
    return {
      fare: [],
    };
  }
  const fare = selectedTrip.returnFareId
    ? selectedTrip.returnFareId
    : selectedTrip.outgoingFareId;
  const fareDetails = tripDetails.fareDetails.find((f) => f.id === fare);
  return {
    fare: fareDetails?.paxPricings,
  };
};

// This should always return false until we offer Virtual Interlining (i.e. multi-ticket one-way fare).
export const isOutgoingMultiTicketSelector = createSelector(
  tripDetailsByIdSelector,
  selectedTripSelector,
  (tripDetailsMap, selectedTrip): boolean => {
    const trip = tripDetailsMap[selectedTrip?.tripId || ""];
    const selectedOWRTrip = selectedTrip as ISelectedTrip;
    if (trip && selectedOWRTrip.outgoingFareId) {
      return !!trip.fareDetails.find(
        (f) => f.id === selectedOWRTrip.outgoingFareId
      )?.multiTicket;
    }
    return false;
  }
);

export const isReturnMultiTicketSelector = createSelector(
  tripDetailsByIdSelector,
  selectedTripSelector,
  (tripDetailsMap, selectedTrip): boolean => {
    const trip = tripDetailsMap[selectedTrip?.tripId || ""];
    const selectedOWRTrip = selectedTrip as ISelectedTrip;
    if (trip && selectedOWRTrip.returnFareId) {
      return !!trip.fareDetails.find(
        (f) => f.id === selectedOWRTrip.returnFareId
      )?.multiTicket;
    }
    return false;
  }
);

export const areBasicFaresFilteredOutSelector = createSelector(
  getFareclassOptionFilter,
  (fareclassOptionFilter): boolean => {
    return (
      !fareclassOptionFilter.basic &&
      fareclassOptionFilter.luxury &&
      fareclassOptionFilter.enhanced &&
      fareclassOptionFilter.premium &&
      fareclassOptionFilter.standard
    );
  }
);

export const shopFilterSelector = createSelector(
  areBasicFaresFilteredOutSelector,
  getStopsOptionFilter,
  (areBasicFaresFilteredOut, stopsOption): ShopFilter => {
    let tripFilter = ShopFilter.NoFilter;
    if (areBasicFaresFilteredOut) {
      if (stopsOption === SliceStopCountFilter.NONE) {
        tripFilter = ShopFilter.NonStopNoLCC;
      } else {
        tripFilter = ShopFilter.NoLCC;
      }
    } else if (stopsOption === SliceStopCountFilter.NONE) {
      tripFilter = ShopFilter.NonStop;
    }
    return tripFilter;
  }
);

export const fetchAncillaryOfferRequestParametersSelector = createSelector(
  shopFilterSelector,
  selectedTripSelector,
  passengerCountSelector,
  (
    shopFilter,
    selectedTrip,
    passengerCount
  ): AncillaryFetchOfferRequest | null => {
    const tripId = selectedTrip.tripId;

    const selectedOWRTrip = selectedTrip as ISelectedTrip;
    const selectedMulticityTrip = selectedTrip as ISelectedMulticityTrip;

    const fareId =
      selectedOWRTrip.returnFareId ??
      selectedOWRTrip.outgoingFareId ??
      selectedMulticityTrip.departureFareId;

    if (tripId && fareId) {
      return {
        tripId,
        fareId,
        tripFilter: shopFilter,
        passengers: { ...passengerCount } as { [key in string]: number },
      };
    } else {
      return null;
    }
  }
);

export const alertKeySelector = createSelector(
  shopFilterSelector,
  tripSummariesSelector,
  flightsByFlightShopTypeSelector,
  getDepartureDate,
  getReturnDate,
  getOrigin,
  getDestination,
  (
    shopFilter,
    tripSummaries,
    flights,
    departureDate,
    returnDate,
    origin,
    destination
  ): FlightKey | null => {
    if (tripSummaries.length < 1 && !flights) {
      return null;
    }

    let summary = {
      originCode: "",
      destinationCode: "",
      departureTime: "",
      returningDepartureTime: "",
    };

    // Flight Shop v0
    if (tripSummaries[0]) {
      const tempSummary = tripSummaries[0];
      summary.originCode = tempSummary.outgoingSlice.originCode;
      summary.destinationCode = tempSummary.outgoingSlice.destinationCode;
      summary.departureTime = departureDate
        ? dayjs(departureDate).format("YYYY-MM-DD")
        : dayjs(tempSummary.outgoingSlice.departureTime).format("YYYY-MM-DD");
      if (returnDate) {
        summary.returningDepartureTime =
          dayjs(returnDate).format("YYYY-MM-DD") ||
          (tempSummary.returningSlice
            ? dayjs(tempSummary.returningSlice.departureTime).format(
                "YYYY-MM-DD"
              )
            : "");
      }
    } else if (flights && Object.values(flights?.trips)[0]) {
      //Flight shop v2
      const flight = Object.values(flights?.trips)[0];

      const departureSlice = flights.slices[flight.outbound];
      const returnSlice = flight.return ? flights.slices[flight.return] : null;
      summary.originCode = departureSlice?.origin;
      summary.destinationCode = departureSlice?.destination;
      summary.departureTime = departureDate
        ? dayjs(departureDate).format("YYYY-MM-DD")
        : dayjs(departureSlice?.departure).format("YYYY-MM-DD");
      if (returnDate) {
        summary.returningDepartureTime =
          dayjs(returnDate).format("YYYY-MM-DD") ||
          (returnSlice?.departure
            ? dayjs(returnSlice.departure).format("YYYY-MM-DD")
            : "");
      }
    }

    return {
      AlertKey: AlertKeyEnum.FlightKey,
      value: {
        filter: shopFilter,
        origin: origin
          ? origin.id.code
          : {
              code: summary.originCode,
              regionType: RegionType.Airport,
            },
        destination: destination
          ? destination.id.code
          : {
              code: summary.destinationCode,
              regionType: RegionType.Airport,
            },
        departureDate: departureDate
          ? dayjs(departureDate).format("YYYY-MM-DD")
          : dayjs(summary.departureTime).format("YYYY-MM-DD"),
        ...(returnDate && {
          returnDate:
            dayjs(returnDate).format("YYYY-MM-DD") ||
            dayjs(summary.returningDepartureTime).format("YYYY-MM-DD"),
        }),
      },
    };
  }
);

export const isWatchingSelector = createSelector(
  alertKeySelector,
  watchesSelector,
  (alertKey, watches) => {
    return watches && !!watches.find((watch) => isEqual(watch.key, alertKey));
  }
);

export const tripCategoryFromSelectedTripDetailsSelector = createSelector(
  selectedTripDetailsSelector,
  (tripDetails): TripCategory => {
    return tripDetails && tripDetails?.slices.length > 1
      ? TripCategory.ROUND_TRIP
      : TripCategory.ONE_WAY;
  }
);

// note: the /tripPrice endpoint requires selecting a passenger, which hasn't happened yet in the flight-shop-customize step
export const customizeCheckoutBreakdownTotalPricesSelector = createSelector(
  selectedFareDetailsSelector,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
  selectedCfarOfferPricesSelector,
  selectedDisruptionProtectionOfferPricesSelector,
  (
    selectedFare,
    accountReferenceId,
    cfarOfferPrices,
    disruptionProtectionOfferPrices
  ): { fiat: FiatPrice; rewards: RewardsPrice | undefined } | null => {
    const fareTotalPrices = selectedFare?.paxPricings?.[0]?.pricing.total;
    if (fareTotalPrices) {
      return getCustomizeCheckoutBreakdownTotalPrices({
        fareTotalPrices,
        cfarOfferPrices,
        disruptionProtectionOfferPrices,
        accountReferenceId,
      });
    }

    return null;
  }
);

export const getFlightShopEntryPoint = (state: IStoreState) =>
  state.flightShop.flightShopEntryPoint;

export const getPaymentMethods = (state: IStoreState) =>
  state.flightShop.paymentMethods;

export const getAirEntryProperties = createSelector(
  getAgentEmail,
  getOrigin,
  getDestination,
  getDepartureDate,
  getReturnDate,
  getPassengersTotal,
  getIsFirstLaunch,
  getWatches,
  selectedTripDetailsSelector,
  getRewardsAccounts,
  getTravelWalletCredit,
  getCreditBreakdown,
  getSelectedAccount,
  getFlightShopEntryPoint,
  getTripCategory,
  getMulticityRoutes,
  getFareclassOptionFilter,
  getStopsOption,
  getApplyUserFlightPreferences,
  getShopExperiments,
  getPotentialCrossSellOffers,
  getPaymentMethods,
  (
    agentEmail,
    origin,
    destination,
    departureDate,
    returnDate,
    passengerCount,
    isFirstLaunch,
    watches,
    tripDetails,
    rewardsAccounts,
    credit,
    creditBreakdown,
    selectedAccount,
    flightShopEntryPoint,
    tripCategory,
    multicityRoutes,
    fareclassOptionFilter,
    stopsOption,
    applyUserFlightPreferences,
    experiments,
    potentialCrossSellOffers,
    paymentMethods
  ): AirEntryProperties => {
    const isMulticity = tripCategory === TripCategory.MULTI_CITY;
    // if multicity, overwrite the following since values may be present in the OW/RT redux state
    const firstSliceOrigin = multicityRoutes[0].origin?.id.code.code || "";
    const firstSliceDepartureDate = multicityRoutes[0].departureDate;
    const formattedFirstSliceDepartureDate = firstSliceDepartureDate
      ? dayjs(firstSliceDepartureDate).format("YYYY-MM-DD")
      : "";
    const lastSliceDestination =
      multicityRoutes[multicityRoutes.length - 1]?.destination?.id.code.code ||
      "";
    const appliedFareClasses = Object.keys(fareclassOptionFilter).filter(
      (key) => fareclassOptionFilter[key]
    );

    const isHotelCrossSellV3Experiment =
      experiments?.[HOTEL_CROSS_SELL_V3_EXPERIMENT] !== CONTROL;

    return {
      delegated_to: agentEmail || "",
      // BF 1552 Modify properties to support multi-city flights once needed
      first_launch: isFirstLaunch,
      origin: isMulticity
        ? firstSliceOrigin
        : !origin
        ? tripDetails
          ? tripDetails.slices[0].originName
          : ""
        : `${origin.id.code.regionType}/${origin.id.code.code}`,
      destination: isMulticity
        ? lastSliceDestination
        : !destination
        ? tripDetails
          ? tripDetails.slices[0].destinationName
          : ""
        : `${destination.id.code.regionType}/${destination.id.code.code}`,
      origin_country_code: isMulticity
        ? ""
        : origin?.id.code.code ||
          (tripDetails ? tripDetails.slices[0].originCode : ""),
      destination_country_code: isMulticity
        ? ""
        : destination?.id.code.code ||
          (tripDetails ? tripDetails.slices[0].destinationCode : ""),
      departure_date: isMulticity
        ? formattedFirstSliceDepartureDate
        : !departureDate
        ? tripDetails
          ? tripDetails.slices[0].departureTime
          : ""
        : dayjs(departureDate).format("YYYY-MM-DD"),
      ...(tripDetails &&
        tripDetails.slices.length > 1 && {
          return_date: dayjs(tripDetails.slices[1].departureTime).format(
            "YYYY-MM-DD"
          ),
        }),
      ...(!isMulticity &&
        returnDate && {
          return_date: dayjs(returnDate).format("YYYY-MM-DD"),
        }),
      number_of_active_watches: watches.length,
      pax_total: passengerCount,
      trip_type: isMulticity
        ? "multi_city"
        : returnDate || (tripDetails && tripDetails.slices.length > 0)
        ? "round_trip"
        : "one_way",
      rewards_accounts: rewardsAccounts
        .map((r) => r.productDisplayName)
        .join(","),
      has_credits: !!credit?.amount?.amount,
      credit_balance: !!credit?.amount?.amount
        ? Math.abs(credit.amount.amount)
        : 0,
      vx_statement_credit_balance:
        creditBreakdown
          ?.filter((b) => b.CreditDetail === "Statement")
          .reduce(
            (prev, curr) =>
              prev + (curr as StatementCreditDetail).usableAmount.amount,
            0
          ) || 0,
      customer_account_role: selectedAccount?.customerAccountRole,
      entry_type: flightShopEntryPoint,
      fareclass_filter: appliedFareClasses.length
        ? appliedFareClasses
        : undefined,
      non_stop: stopsOption === SliceStopCountFilter.NONE,
      preferences_applied: applyUserFlightPreferences,
      cross_sell_displayed:
        isHotelCrossSellV3Experiment && potentialCrossSellOffers.length > 0,
      card_on_file: paymentMethods.length > 0,
    };
  }
);

export const getMultiCityAirEntryProperties = createSelector(
  getMulticityRoutes,
  selectedTripDetailsSelector,
  getPassengersTotal,
  getIsFirstLaunch,
  getWatches,
  getRewardsAccounts,
  getTravelWalletCredit,
  getCreditBreakdown,
  getSelectedAccount,
  getFlightShopEntryPoint,
  (
    multicityRoutes,
    tripDetails,
    passengerCount,
    isFirstLaunch,
    watches,
    rewardsAccounts,
    credit,
    creditBreakdown,
    selectedAccount,
    flightShopEntryPoint
  ): AirMultiCityEntryProperties => {
    const propsArray = multicityRoutes.map((r, idx) => {
      const origin = !r.origin
        ? tripDetails && tripDetails.slices[idx]
          ? tripDetails.slices[idx].originName
          : ""
        : `${r.origin.id.code.regionType}/${r.origin.id.code.code}`;
      const destination = !r.destination
        ? tripDetails && tripDetails.slices[idx]
          ? tripDetails.slices[idx].destinationName
          : ""
        : `${r.destination.id.code.regionType}/${r.destination.id.code.code}`;

      const originCountryCode =
        r.origin?.id.code.code ||
        (tripDetails && tripDetails.slices[idx]
          ? tripDetails.slices[idx].originCode
          : "");

      const destinationCountryCode =
        r.destination?.id.code.code ||
        (tripDetails && tripDetails.slices[idx]
          ? tripDetails.slices[idx].originCode
          : "");

      const departureDate = !r.departureDate
        ? tripDetails && tripDetails.slices[idx]
          ? tripDetails.slices[idx].departureTime
          : ""
        : dayjs(r.departureDate).format("YYYY-MM-DD");
      return {
        origin: origin,
        destination: destination,
        originCountryCode: originCountryCode,
        destinationCountryCode: destinationCountryCode,
        departureDate: departureDate,
      };
    });
    const flight1 = propsArray[0];
    const flight2 = propsArray[1];
    const flight3 = propsArray[2];
    const flight4 = propsArray[3];
    const flight5 = propsArray[4];
    return {
      first_launch: isFirstLaunch,
      flight1_origin: flight1.origin,
      flight1_origin_country_code: flight1.originCountryCode,
      flight1_destination: flight1.destination,
      flight1_destination_country_code: flight1.destinationCountryCode,
      flight1_departure_date: flight1.departureDate,
      flight2_origin: flight2.origin,
      flight2_origin_country_code: flight2.originCountryCode,
      flight2_destination: flight2.destination,
      flight2_destination_country_code: flight2.destinationCountryCode,
      flight2_departure_date: flight2.departureDate,
      flight3_origin: flight3?.origin,
      flight3_origin_country_code: flight3?.originCountryCode,
      flight3_destination: flight3?.destination,
      flight3_destination_country_code: flight3?.destinationCountryCode,
      flight3_departure_date: flight3?.departureDate,
      flight4_origin: flight4?.origin,
      flight4_origin_country_code: flight4?.originCountryCode,
      flight4_destination: flight4?.destination,
      flight4_destination_country_code: flight4?.destinationCountryCode,
      flight4_departure_date: flight4?.departureDate,
      flight5_origin: flight5?.origin,
      flight5_origin_country_code: flight5?.originCountryCode,
      flight5_destination: flight5?.destination,
      flight5_destination_country_code: flight5?.destinationCountryCode,
      flight5_departure_date: flight5?.departureDate,
      number_of_active_watches: watches.length,
      trip_type: "multi_city",
      pax_total: passengerCount,
      rewards_accounts: rewardsAccounts
        .map((r) => r.productDisplayName)
        .join(","),
      has_credits: !!credit?.amount?.amount,
      credit_balance: !!credit?.amount?.amount
        ? Math.abs(credit.amount.amount)
        : 0,
      vx_statement_credit_balance:
        creditBreakdown
          ?.filter((b) => b.CreditDetail === "Statement")
          .reduce(
            (prev, curr) =>
              prev + (curr as StatementCreditDetail).usableAmount.amount,
            0
          ) || 0,
      customer_account_role: selectedAccount?.customerAccountRole,
      entry_type: flightShopEntryPoint,
    };
  }
);

export const priceFreezeUserSelectedDurationPropertiesSelector = createSelector(
  isPriceFreezeDurationEnabledSelector,
  getPriceFreezeOfferWithSuggested,
  priceFreezeMinMaxDurationsPropertiesSelector,
  (
    isPriceFreezeDurationEnabled,
    priceFreezeOfferWithSuggested,
    priceFreezeMinMaxDurationsProperties
  ): PriceFreezeUserSelectedDurationProperties | undefined => {
    return isPriceFreezeDurationEnabled
      ? {
          ...priceFreezeMinMaxDurationsProperties,
          default_fee_price_per_traveler_usd:
            priceFreezeOfferWithSuggested?.offer.perPaxAmount.fiat.value,
          default_duration_offered:
            priceFreezeOfferWithSuggested?.offer.timeToLive.inSeconds,
        }
      : undefined;
  }
);

export const getViewedFlightListProperties = createSelector(
  predictionSelector,
  getAirEntryProperties,
  getSelectedAccount,
  flightShopProgressSelector,
  flightShopMulticityProgressSelector,
  orderedAndFilteredFlightIdsSelector,
  allTripSummariesSelector,
  priceFreezeUserSelectedDurationPropertiesSelector,
  (
    prediction,
    airEntryProps,
    account,
    progress,
    multicityProgress,
    flights,
    tripSummariesById,
    priceFreezeUserSelectedDurationProperties
  ): ViewedFlightListProperties => {
    const numberOfFares = flights.reduce((fareCount: number, flight) => {
      return fareCount + tripSummariesById[flight].tripFares.length;
    }, 0);

    return {
      ...airEntryProps,
      ...priceFreezeUserSelectedDurationProperties,
      preferences_applied: undefined,
      flights_shown:
        progress === FlightShopStep.ChooseDeparture ||
        progress === FlightShopStep.ChooseReturn ||
        [
          MulticityFlightShopStep.ChooseDeparture0,
          MulticityFlightShopStep.ChooseDeparture1,
          MulticityFlightShopStep.ChooseDeparture2,
          MulticityFlightShopStep.ChooseDeparture3,
          MulticityFlightShopStep.ChooseDeparture4,
        ].includes(multicityProgress)
          ? flights.length
          : undefined,
      slice_direction:
        progress === FlightShopStep.ChooseReturn
          ? "return"
          : progress === FlightShopStep.ChooseDeparture
          ? "outbound"
          : undefined,
      fares_shown_outbound:
        progress === FlightShopStep.ChooseDeparture ? numberOfFares : undefined,
      fares_shown_return:
        progress === FlightShopStep.ChooseReturn ? numberOfFares : undefined,
      account_type_selected: account?.accountDisplayName!,
      account_use_type: account?.accountUseType,
      customer_account_role: account?.customerAccountRole,
      account_allow_rewards_redemption: account?.allowRewardsRedemption,
      dealness: prediction?.dealness!,
      recommendation: !prediction
        ? "null"
        : prediction.dealness === Dealness.Wait
        ? "wait"
        : "buy",
    };
  }
);

export const getSelectedOutgoingSliceProperties = createSelector(
  getViewedFlightListProperties,
  selectedTripSelector,
  getCorporateTravelProperties,
  (
    viewedFlightListProperties,
    selectedTrip,
    corporateTravelProperties
  ): SelectedSliceProperties => {
    const selectedOWRTrip = selectedTrip as ISelectedTrip;
    const selectedMcTrip = selectedTrip as ISelectedMulticityTrip;

    return {
      ...viewedFlightListProperties,
      fare_class: getHopperFareRatingName(
        selectedOWRTrip.outgoingFareRating || selectedMcTrip.departureFareRating
      ),
      ...(isCorpTenant(config.TENANT) && corporateTravelProperties),
    };
  }
);

export const getSelectedReturnSliceProperties = createSelector(
  getViewedFlightListProperties,
  selectedTripSelector,
  (
    viewedFlightListProperties,
    selectedTrip
  ): SelectedSliceProperties | null => {
    if (!viewedFlightListProperties) {
      return null;
    }

    const selectedOWRTrip = selectedTrip as ISelectedTrip;
    return {
      ...viewedFlightListProperties,
      fare_class: getHopperFareRatingName(selectedOWRTrip.returnFareRating),
    };
  }
);

export const getTravelOfferProperties = createSelector(
  getBestOfferOverall,
  (bestOfferOverall): ITrackingProperties | null => ({
    properties: {
      ...bestOfferOverall?.trackingPropertiesV2?.properties,
      has_offer: !!bestOfferOverall,
    },
    encryptedProperties: [
      bestOfferOverall?.trackingPropertiesV2?.encryptedProperties ?? "",
    ],
  })
);

export const getPriceFreezeOfferCheapestTripTripId = (
  state: IStoreState
): string => state.flightShop.priceFreezeOffer?.cheapestTrip.tripId || "";

export const getPriceFreezeOfferCheapestTripFareId = (
  state: IStoreState
): string => state.flightShop.priceFreezeOffer?.cheapestTrip.fareId || "";

export const getPriceFreezeCheapestEligibleCustomtOffer = (
  state: IStoreState
): Offer | null =>
  state.flightFreeze.cheapestEligibleFlightCustomPriceFreezeOffer;

export const currentPriceFreezeOfferFlightShopSelector = createSelector(
  customPriceFreezeOfferSelector,
  getPriceFreezeOfferWithSuggested,
  flightShopProgressSelector,
  getPriceFreezeCheapestEligibleCustomtOffer,
  (
    customPriceFreezeOffer,
    shopSummaryPriceFreezeOffer,
    flightShopProgress,
    cheapestEligibleCustomOffer
  ): Offer | undefined => {
    switch (flightShopProgress) {
      case FlightShopStep.ReviewItinerary:
        // note: on the review itinerary page, the FE should NOT attach the shopSummary PF offer
        return customPriceFreezeOffer ?? undefined;
      default:
        return (
          customPriceFreezeOffer ??
          cheapestEligibleCustomOffer ??
          shopSummaryPriceFreezeOffer?.offer ??
          undefined
        );
    }
  }
);

export const getCheapestEligiblePriceFreezeOfferCap = createSelector(
  getPriceFreezeCheapestEligibleCustomtOffer,
  (offer) => {
    return offer?.cap;
  }
);

export const getCheapestEligiblePriceFreezeOfferFiat = createSelector(
  getPriceFreezeCheapestEligibleCustomtOffer,
  (offer) => {
    return offer?.perPaxAmount.fiat;
  }
);

export const getCheapestEligiblePriceFreezeOfferRewards = createSelector(
  getPriceFreezeCheapestEligibleCustomtOffer,
  (offer) => {
    return offer?.perPaxAmount.rewards;
  }
);

export const getCheapestEligiblePriceFreezeOfferDuration = createSelector(
  getPriceFreezeCheapestEligibleCustomtOffer,
  (offer) => {
    return offer?.timeToLive;
  }
);

export const getPriceFreezeOfferCap = createSelector(
  currentPriceFreezeOfferFlightShopSelector,
  (offer) => {
    return offer?.cap;
  }
);

export const getPriceFreezeOfferFiat = createSelector(
  currentPriceFreezeOfferFlightShopSelector,
  (offer) => {
    return offer?.perPaxAmount.fiat;
  }
);

export const getPriceFreezeOfferRewards = createSelector(
  currentPriceFreezeOfferFlightShopSelector,
  (offer) => {
    return offer?.perPaxAmount.rewards;
  }
);

export const getPriceFreezeOfferDuration = createSelector(
  currentPriceFreezeOfferFlightShopSelector,
  (offer) => {
    return offer?.timeToLive;
  }
);

export const getPriceFreezeCheapestFrozenPrice = createSelector(
  getPriceFreezeOfferWithSuggested,
  tripDetailsByIdSelector,
  getSelectedAccount,
  (
    priceFreezeOffer,
    tripDetailsById,
    account
  ): { fiat: FiatPrice; rewards: RewardsPrice | undefined } | undefined => {
    if (!priceFreezeOffer) {
      return undefined;
    }

    const { cheapestTrip } = priceFreezeOffer;
    const tripDetails = tripDetailsById[cheapestTrip.tripId];

    if (!tripDetails) {
      return undefined;
    }

    const fareDetails = tripDetails.fareDetails.find(
      (f) => f.id === cheapestTrip.fareId
    )?.paxPricings?.[0].pricing.total;

    if (!fareDetails) {
      return undefined;
    }

    return {
      fiat: fareDetails.fiat,
      rewards:
        account && (account.allowRewardsRedemption ?? true)
          ? fareDetails.rewards[account?.accountReferenceId]
          : undefined,
    };
  }
);

export const getViewedPriceFreezeProperties = createSelector(
  getPriceFreezeCustomOffer,
  getPriceFreezeOfferWithSuggested,
  getPriceFreezeCheapestFrozenPrice,
  (
    customPriceFreeze,
    priceFreezeWithSuggested,
    cheapestFrozenPrice
  ): ViewedPriceFreezeProperties | null => {
    if (!priceFreezeWithSuggested || !cheapestFrozenPrice) {
      return null;
    }

    const offer = customPriceFreeze ?? priceFreezeWithSuggested.offer;

    return {
      price_freeze_shown: Boolean(offer?.id),
      price_freeze_duration: offer?.timeToLive?.inSeconds || 0,
      price_freeze_otm_cap_usd: offer?.cap.value.amount || 0,
      price_freeze_cost_per_pax: offer?.perPaxAmount.fiat.value || 0,
      current_lowest_price_usd: cheapestFrozenPrice?.fiat.value || 0,
      price_freeze_total_cost: offer?.totalAmount.fiat.value || 0,
    };
  }
);

export const getPriceFreezeOffer = createSelector(
  getPriceFreezeOfferCheapestTripTripId,
  tripDetailsByIdSelector,
  getPriceFreezeOfferCheapestTripFareId,
  getPriceFreezeOfferWithSuggested,
  tripSummariesByIdSelector,
  flightsByFlightShopTypeSelector,
  hasActiveRefundableFareInFlightShopSelector,
  (
    id: string,
    tripDetailsById,
    fareId,
    offerWithSuggested,
    tripSummaries,
    flights,
    hasActiveRefundableFareInFlightShop
  ): IPriceFreezeOfferEntries | null => {
    if (
      !offerWithSuggested ||
      hasActiveRefundableFareInFlightShop ||
      isCorpTenant(config.TENANT)
    ) {
      return null;
    }

    const tripSummary = tripSummaries[id];
    // note: tripSummary is from v1, and flights is from v2
    const airports = tripSummary
      ? tripSummary.context.airports
      : flights
      ? flights.airports
      : {};

    const tripDetails: TripDetails | null = tripDetailsById[id] ?? null;
    if (tripDetails) {
      const fares = tripDetails?.fareDetails.find((f) => f.id === fareId);
      return {
        departureFlight: {
          ...getSliceDetails(true, tripDetails, airports),
          fareClass: fares?.slices[0]?.fareShelf?.shortBrandName ?? "",
        },
        returnFlight: tripDetails.slices[1]
          ? {
              ...getSliceDetails(false, tripDetails, airports),
              fareClass: fares?.slices[1]?.fareShelf?.shortBrandName ?? "",
            }
          : null,
      };
    }
    return {
      departureFlight: null,
      returnFlight: null,
    };
  }
);

export const getOutboundFlightList = createSelector(
  flightsByFlightShopTypeSelector,
  (flights): IFlightListData[] => {
    return (flights as Flights)?.outbound || [];
  }
);

export const getReturnFlightList = createSelector(
  flightsByFlightShopTypeSelector,
  selectedTripSelector,
  isThebesHackerFaresV2Cap1ExperimentAvailable,
  (OWRTFlights, selectedTrip, isHackerFareV2): IFlightListData[] => {
    const selectedOWRTrip = selectedTrip as ISelectedTrip;
    const flights = OWRTFlights as Flights;
    if (!selectedOWRTrip.outgoingSliceId || !flights) return [];

    const getReturnFlights = (returnTripIds: string[]) => {
      // @ts-ignore

      const JETBLUE_CODE = "B6";
      const isOutgoingJetBlue =
        selectedOWRTrip.outgoingSliceId &&
        flights.slices[selectedOWRTrip.outgoingSliceId].marketingAirline ===
          JETBLUE_CODE;
      // Flight can have a marketingAirline of B6 but can be a different operatingAirline (like AA) so this checks if it's truly B6 (JetBlue)
      const isOutgoingOperatedByJetBlue =
        isOutgoingJetBlue &&
        selectedOWRTrip.outgoingSliceId &&
        flights.slices[selectedOWRTrip.outgoingSliceId].segments.every(
          (segment) => segment.operatingAirline === JETBLUE_CODE
        );

      const selectedOutgoingFare = selectedOWRTrip.outgoingFareId
        ? flights.fares[selectedOWRTrip.outgoingFareId]
        : null;
      const selectedOutgoingFareSliceId = selectedOutgoingFare
        ? flights.fareSlices[selectedOutgoingFare.outbound].id
        : null;

      return returnTripIds.reduce(
        (returnFlightList: IFlightListData[], tripId: string) => {
          const trip = flights.trips[tripId];
          const returnSliceId = flights.trips[tripId].return || "";

          const tripFares = trip.fares.map(
            (fareId: string) => flights.fares[fareId]
          );

          // Logic for filtering return flights
          const getFilteredFares = () => {
            // If outgoing isn't JetBlue, do nothing because filtering is only needed for JetBlue flights
            if (!isOutgoingJetBlue || !isOutgoingOperatedByJetBlue) {
              return tripFares.filter((tripFare) => {
                if (!tripFare.return) return true;

                const returnFareSlice = flights.fareSlices[tripFare.return];
                const outgoingFareSlice = flights.fareSlices[tripFare.outbound];

                // if the fare has the selected outgoing FARE SLICE, the return fare slice must be valid (even though the fare classese are differnt - Hacker fare)
                if (
                  selectedOutgoingFareSliceId &&
                  tripFare.outbound == selectedOutgoingFareSliceId
                ) {
                  return true;
                  // even if the fare doesn't have the selected outgoing fare slice, we will show the same or higher fare classes (upsell)
                  // as long as the matching outbound fare is higher or equal to the selected outboound
                } else if (
                  selectedOWRTrip.outgoingFareRating !== null &&
                  selectedOWRTrip.outgoingFareRating !== undefined
                ) {
                  if (
                    // Prevents mix/matching if HFV2, added per Incident-2367
                    isHackerFareV2 &&
                    selectedOutgoingFare?.multiTicketType ===
                      MultiTicketTypeEnum.HackerFare
                  ) {
                    return (
                      tripFare.multiTicketType === MultiTicketTypeEnum.Single ||
                      (returnFareSlice.fareShelf.value ===
                        selectedOWRTrip.outgoingFareRating &&
                        outgoingFareSlice.fareShelf.value ===
                          selectedOWRTrip.outgoingFareRating)
                    );
                  } else {
                    return (
                      returnFareSlice.fareShelf.value >=
                        selectedOWRTrip.outgoingFareRating &&
                      outgoingFareSlice.fareShelf.value >=
                        selectedOWRTrip.outgoingFareRating
                    );
                  }
                } else {
                  return false;
                }
              });
            }
            // Logic for filtering for JetBlue (B6 flight):
            return tripFares.filter((tripFare) => {
              if (!tripFare.return) return true;
              const returnFareSlice = flights.fareSlices[tripFare.return];
              const outgoingFareSlice = flights.fareSlices[tripFare.outbound];
              // Similiar to outgoing, flight can have marketingAirline of B6 but can be a different operatingAirline (like AA) so this checks if it's truly B6 (JetBlue)
              const isReturnSliceOperatedByJetBlue = flights.slices[
                returnFareSlice.slice
              ].segments.every(
                (segment) => segment.operatingAirline === JETBLUE_CODE
              );

              // If outgoing is JetBlue Basic (fareRating = 0), only show JetBlue Basic return fares
              if (
                flights.slices[returnFareSlice.slice].marketingAirline ===
                  JETBLUE_CODE &&
                isReturnSliceOperatedByJetBlue &&
                selectedOWRTrip.outgoingFareRating === 0
              ) {
                return returnFareSlice.fareShelf.value === 0;
              }

              if (
                selectedOWRTrip.outgoingFareRating !== null &&
                selectedOWRTrip.outgoingFareRating !== undefined
              ) {
                // even if the fare doesn't have the selected outgoing fare slice, we will show the same or higher fare classes (upsell)
                // as long as the matching outbound fare is higher or equal to the selected outboound
                return (
                  returnFareSlice.fareShelf.value >=
                    selectedOWRTrip.outgoingFareRating &&
                  outgoingFareSlice.fareShelf.value >=
                    selectedOWRTrip.outgoingFareRating
                );
              } else {
                return false;
              }
            });
          };

          let filteredTripFares = getFilteredFares();

          if (filteredTripFares.length > 0) {
            returnFlightList.push({
              slice: returnSliceId,
              fares: filteredTripFares,
            });
          }

          return returnFlightList;
        },
        []
      );
    };

    const flight = flights.outbound.find(
      (flight: Outbound) => flight.slice == selectedOWRTrip.outgoingSliceId
    );

    //Fall back to the first fare next list if fare is not found.
    //This will happen if there was a refresh in flights object.
    //The fallback can be used as all fares next list are identical on the same outbound flight.
    const fare =
      !!flight &&
      (flight.fares.find(
        (fare: OutboundFares) =>
          fare.example.fare === selectedOWRTrip.outgoingFareId
      ) ??
        flight.fares[0]);

    return !!fare && fare.next ? getReturnFlights(fare.next) : [];
  }
);

export const getFlightList = createSelector(
  getOutboundFlightList,
  getReturnFlightList,
  flightShopProgressSelector,
  cfarOffersSelector,
  batchCfarOffersCallStateSelector,
  hasSelectedRefundableFareSelector,
  isRefundableFaresActiveInFlightShopSelector,
  hasUpdatedCfarOffersForReturnFlightsSelector,
  (
    outgoingFlightList,
    returnFlightList,
    flightShopProgress,
    cfarOffers,
    batchCfarOffersCallState,
    hasSelectedRefundableFare,
    isRefundableFaresActiveInFlightShop,
    hasUpdatedCfarOffersForReturnFlights
  ): IFlightListData[] => {
    switch (flightShopProgress) {
      case FlightShopStep.ChooseReturn:
        if (
          !isEmpty(cfarOffers) &&
          batchCfarOffersCallState === CallState.Success &&
          hasUpdatedCfarOffersForReturnFlights &&
          hasSelectedRefundableFare &&
          isRefundableFaresActiveInFlightShop
        ) {
          return returnFlightList.filter((flight) =>
            flight.fares.some((fare: any) =>
              isCfarEligible(fare.tripId, fare.id, cfarOffers)
            )
          );
        } else {
          return returnFlightList;
        }
      default:
        return outgoingFlightList;
    }
  }
);

export const getMulticityFlightList = createSelector(
  multicityFlightsSelector,
  flightShopMulticityProgressSelector,
  selectedMulticityTripsSelector,
  (
    multicityFlights,
    multicityFlightShopProgress,
    selectedMulticityTrips
  ): IFlightListData[] => {
    if (isEmpty(multicityFlights)) return [];
    switch (multicityFlightShopProgress) {
      case MulticityFlightShopStep.ChooseDeparture0:
        return multicityFlights.departures;
      case MulticityFlightShopStep.ChooseDeparture1:
      case MulticityFlightShopStep.ChooseDeparture2:
      case MulticityFlightShopStep.ChooseDeparture3:
      case MulticityFlightShopStep.ChooseDeparture4:
        try {
          const getFlightList = (
            departures: SliceDeparture[],
            currentIndex: number
          ): any => {
            const currentSelectedTrip = selectedMulticityTrips[currentIndex];
            const currentDepartureSliceId =
              currentSelectedTrip.departureSliceId;
            const currentDepartureFareId = currentSelectedTrip.departureFareId;
            const foundSliceDeparture = departures.find(
              (dep) => dep.slice === currentDepartureSliceId
            );
            const foundDepartureFare = foundSliceDeparture?.fares.find(
              (fare) => fare.example.fare === currentDepartureFareId
            ) as FareSliceDeparture;
            if (currentIndex === multicityFlightShopProgress - 1)
              return foundDepartureFare.next;
            else
              return getFlightList(
                foundDepartureFare.next as SliceDeparture[],
                currentIndex + 1
              );
          };

          const flights = getFlightList(multicityFlights.departures, 0);

          return flights as IFlightListData[];
        } catch {
          return [];
        }
      default:
        return [];
    }
  }
);

export interface IShopFilterSelector {
  airlineOptions: IAirlineOptions[];
  airportOptions: IAirportOptions[];
  flightNumbersByAirline: IFlightNumbersByAirlineCode;
  priceMin: { value: number };
  priceMax: { value: number };
}

export const allFlightShopFilterSelector = createSelector(
  getTripCategory,
  flightsByFlightShopTypeSelector,
  getFlightList,
  getMulticityFlightList,
  (
    tripCategory,
    flights,
    OWRTFlightList,
    multicityFlightList
  ): IShopFilterSelector => {
    const airlines = new Set<AirlineCode>();
    const allAirlines: IAirlineOptions[] = [];
    const airports = new Set<string>();
    const allAirports: IAirportOptions[] = [];
    const flightNumbersByAirline: IFlightNumbersByAirlineCode = {};
    const isMulticity = tripCategory === TripCategory.MULTI_CITY;
    const flightList = isMulticity ? multicityFlightList : OWRTFlightList;

    let min = { value: Number.MAX_SAFE_INTEGER };
    let max = { value: 0 };

    const flightShopFilters = {
      airlineOptions: allAirlines,
      airportOptions: allAirports,
      flightNumbersByAirline,
      priceMin: min,
      priceMax: max,
      policyStatus: true,
    };

    if (!flights) return flightShopFilters;

    flightList.forEach((flight: IFlightListData) => {
      const flightSlice = flights.slices[flight.slice];
      if (!flightSlice) return;
      // AIRLINES
      const airlineCode = flightSlice!.marketingAirline;
      if (!airlines.has(airlineCode)) {
        allAirlines.push({
          value: airlineCode,
          label: flights?.airlines[airlineCode]?.displayName || airlineCode,
        });
      }
      airlines.add(airlineCode);

      //AIRPORTS
      const airportCode = flightSlice.origin;
      const airportName = flights.airports[flightSlice.origin].name;
      if (!airports.has(airportCode)) {
        allAirports.push({ value: airportCode, label: airportName });
      }
      airports.add(airportCode);

      //PRICES
      flight.fares?.forEach((fare: any) => {
        min.value = Math.min(
          fare.amount?.fiat?.value || Number.MAX_SAFE_INTEGER,
          min.value
        );
        max.value = Math.max(fare.amount?.fiat?.value || 0, max.value);
      });

      //FLIGHT NUMBER
      const [{ flightNumber }] = flightSlice.segments;

      if (!flightNumbersByAirline[airlineCode]) {
        flightNumbersByAirline[airlineCode] = [flightNumber];
      } else {
        flightNumbersByAirline[airlineCode] = uniq([
          ...flightNumbersByAirline[airlineCode],
          flightNumber,
        ]);
      }
    });

    return flightShopFilters;
  },
  {
    memoizeOptions: {
      maxSize: 0,
    },
  }
);

export const maxFlightPriceSelectorV2 = createSelector(
  getTripCategory,
  getFlightList,
  getMulticityFlightList,
  (tripCategory, OWRTFlightList, multicityFlightList): number => {
    const isMulticity = tripCategory === TripCategory.MULTI_CITY;
    const flightList = isMulticity ? multicityFlightList : OWRTFlightList;
    let currMax = 0;
    flightList.forEach((flight: IFlightListData) => {
      flight.fares?.forEach((fare: any) => {
        currMax = Math.max(fare.amount?.fiat?.value || 0, currMax);
      });
    });
    return currMax;
  }
);

export const minFlightPriceSelectorV2 = createSelector(
  getTripCategory,
  getFlightList,
  getMulticityFlightList,
  (tripCategory, OWRTFlightList, multicityFlightList): number => {
    const isMulticity = tripCategory === TripCategory.MULTI_CITY;
    const flightList = isMulticity ? multicityFlightList : OWRTFlightList;
    let currMin = Number.MAX_SAFE_INTEGER;
    flightList.forEach((flight: IFlightListData) => {
      flight.fares?.forEach((fare: any) => {
        currMin = Math.min(fare.amount?.fiat?.value || 0, currMin);
      });
    });
    return currMin === Number.MAX_SAFE_INTEGER ? 0 : currMin;
  }
);

/*
  note: this selector is used for controlling the max-price filter tag; it should never show up (therefore return 'false')
  in the rebook-variant of the flight shop component.
*/
export const hasSetMaxPriceFilterSelectorV2 = createSelector(
  getMaxPriceFilter,
  flightShopTypeSelector,
  (maxPriceFilter, flightShopType) => {
    switch (flightShopType) {
      case FlightShopType.DISRUPTION_PROTECTION_EXERCISE: {
        return false;
      }
      default: {
        return maxPriceFilter !== initialFilterOptions.maxPriceFilter;
      }
    }
  }
);

export const hasSetNonFareclassFiltersSelectorV2 = createSelector(
  getHasSetStopsOption,
  getHasSetOutboundTimeRange,
  getHasSetReturnTimeRange,
  getHasSetAirlineFilter,
  getHasSetAirportFilter,
  getHasSetFlightNumberFilter,
  hasSetMaxPriceFilterSelectorV2,
  getHasSetDurationFilter,
  getHasSetPolicyFilter,
  (...args): boolean => {
    return args.some((arg: boolean) => arg);
  }
);

export const filteredFlightsSelectorV2 = createSelector(
  // Search Filter Selectors
  allFiltersSelector,
  getTripCategory,

  // Search Filter Applied
  getHasSetStopsOption,
  getHasSetOutboundTimeRange,
  getHasSetReturnTimeRange,
  getHasSetAirlineFilter,
  getHasSetAirportFilter,
  getHasSetPolicyFilter,
  getHasSetFlightNumberFilter,
  hasSetMaxPriceFilterSelector,
  getHasSetFareClassFilter,
  getBaggageTransfersFilter,
  getHasSetDurationFilter,

  // Shop Selectors
  flightShopProgressSelector,
  getFlightList,
  getMulticityFlightList,
  flightsByFlightShopTypeSelector, // Flights | MulticityFlights
  (
      allFiltersSelector: IFilterState,
      tripCategory,

      hasSetStopsOption,
      hasSetOutboundTimeRange,
      hasSetReturnTimeRange,
      hasSetAirlineFilter,
      hasSetAirportFilter,
      hasSetPolicyFilter,
      hasSetFlightNumberFilter,
      hasSetMaxPriceFilter,
      hasSetFareClassFilter,
      hasBaggageTransfersFilter,
      hasSetDurationFilter,

      shopStep: FlightShopStep,
      OWRTripFlightList,
      multicityFlightList,
      flights
    ): ((invertStopsFilter: boolean) => IFlightListData[]) =>
    (invertStopsFilter) => {
      const {
        stopsOption,
        maxPriceFilter,
        outboundDepartureTimeRange,
        outboundArrivalTimeRange,
        returnDepartureTimeRange,
        returnArrivalTimeRange,
        airlineFilter,
        airportFilter,
        policyFilter,
        flightNumberFilter,
        fareclassOptionFilter,
        durationFilter,
      } = allFiltersSelector;

      const isMulticity = tripCategory === TripCategory.MULTI_CITY;
      const isReturn = shopStep === FlightShopStep.ChooseReturn;

      const flightList = isMulticity ? multicityFlightList : OWRTripFlightList;

      const selectedFaresOptionsArray = Object.keys(
        fareclassOptionFilter
      ).filter((key) => fareclassOptionFilter[key] && key);

      const meetsFilterPredicates = (flight: IFlightListData) => {
        let meetsConditions = true;

        const flightSlice = flights!.slices[flight.slice];
        if (!flightSlice) return false;
        const flightFares = flight.fares;
        const flightFaresDetails = flightFares.map(
          (fare: { example: { fare: string }; id: string }) =>
            flights!.fares[fare.example?.fare ?? fare.id]
        );
        if (hasSetStopsOption) {
          meetsConditions =
            meetsConditions &&
            (invertStopsFilter
              ? !filters.performStopOptionFilterV2(flightSlice, stopsOption)
              : filters.performStopOptionFilterV2(flightSlice, stopsOption));
        }

        if (hasSetOutboundTimeRange && !isReturn) {
          meetsConditions =
            meetsConditions &&
            filters.performTimeRangeFilterV2(
              flightSlice,
              outboundDepartureTimeRange,
              outboundArrivalTimeRange
            );
        }

        if (hasSetReturnTimeRange && isReturn) {
          meetsConditions =
            meetsConditions &&
            filters.performTimeRangeFilterV2(
              flightSlice,
              returnDepartureTimeRange,
              returnArrivalTimeRange
            );
        }

        if (hasSetAirlineFilter) {
          meetsConditions =
            meetsConditions &&
            filters.performAirlineFilterV2(flightSlice, airlineFilter);
        }

        if (hasSetAirportFilter) {
          meetsConditions =
            meetsConditions &&
            filters.performAirportFilterV2(flightSlice, airportFilter);
        }

        if (hasSetFlightNumberFilter) {
          meetsConditions =
            meetsConditions &&
            filters.performFlightNumberFilterV2(
              flightSlice,
              flightNumberFilter
            );
        }

        if (hasSetPolicyFilter) {
          meetsConditions =
            meetsConditions &&
            filters.performPolicyFilterV2(flightFaresDetails, policyFilter);
        }

        if (hasSetMaxPriceFilter) {
          meetsConditions =
            meetsConditions &&
            filters.performMaxPriceFilterV2(flightFares, maxPriceFilter);
        }

        if (hasSetFareClassFilter) {
          meetsConditions =
            meetsConditions &&
            filters.performFareClassFilterV2(
              flights!,
              flight,
              selectedFaresOptionsArray
            );
        }

        if (!hasBaggageTransfersFilter) {
          meetsConditions =
            meetsConditions &&
            filters.performBaggageTransfersFilter(
              flight,
              flights!.slices as Record<string, Slice>
            );
        }

        if (hasSetDurationFilter) {
          meetsConditions =
            meetsConditions &&
            filters.performDurationFilter(flightSlice, durationFilter);
        }

        return meetsConditions;
      };

      const filteredFlights: IFlightListData[] = [];
      if (flights) {
        flightList.forEach((flight) => {
          if (meetsFilterPredicates(flight)) {
            filteredFlights.push(flight);
          }
        });
      }

      return filteredFlights;
    }
);

export const invertedStopsFilterFlightsSelector = createSelector(
  filteredFlightsSelectorV2,
  (filterFlights): IFlightListData[] => filterFlights(true)
);

export const getSortedAndFilteredFlights = createSelector(
  getTripCategory,
  filteredFlightsSelectorV2,
  flightsByFlightShopTypeSelector,
  sortOptionSelector,
  (tripCategory, flightList, flights, sortOption): IFlightListData[] => {
    const filteredFlightList = flightList(false);
    const isMulticity = tripCategory === TripCategory.MULTI_CITY;
    switch (sortOption) {
      case "fareScore":
        return isMulticity
          ? [...sorters.orderByPriceV2(filteredFlightList)]
          : [
              ...sorters.orderByRecommendedV2(
                filteredFlightList,
                flights! as Flights
              ),
            ];
      case "price":
        return [...sorters.orderByPriceV2(filteredFlightList)];
      case "departureTime":
        return sorters.orderByDepartureTimeV2(filteredFlightList, flights!);
      case "arrivalTime":
        return [...sorters.orderByArrivalTimeV2(filteredFlightList, flights!)];
      case "stops":
        return [...sorters.orderByStopsV2(filteredFlightList, flights!)];
      case "duration":
        return [...sorters.orderByDurationV2(filteredFlightList, flights!)];

      default:
        return isMulticity
          ? [...sorters.orderByPriceV2(filteredFlightList)]
          : [
              ...sorters.orderByRecommendedV2(
                filteredFlightList,
                flights! as Flights
              ),
            ];
    }
  }
);

export const getViFlights = createSelector(
  filteredFlightsSelectorV2,
  flightSlicesSelector,
  (flightList, slices) => {
    const viFlights = flightList(false).reduce(
      (acc: IFlightListData[], flight) => {
        if (isFlightMultiTicketType({ flight, slices })) {
          acc = acc.concat(flight);
        }
        return acc;
      },
      []
    );
    return viFlights;
  }
);

export const getHackerFareV2Flights = createSelector(
  filteredFlightsSelectorV2,
  flighFaresSelector,
  flightShopProgressSelector,
  (flightList, fares, progress) => {
    const isDeparture = progress === FlightShopStep.ChooseDeparture;
    const hackerFareV2Flights = isDeparture
      ? flightList(false).reduce((acc: IFlightListData[], flight) => {
          const flightsWithFare = flight.fares.map((fare: any) => {
            return fares?.[fare?.example?.fare];
          });

          const extendedFlightWithFares = {
            ...flight,
            extendedFares: flightsWithFare,
          };
          acc.push(extendedFlightWithFares);
          return acc;
        }, [])
      : flightList(false);
    return hackerFareV2Flights.filter((v: any) => {
      const faresToCheck = isDeparture ? v?.extendedFares : v.fares;
      return faresToCheck?.some(
        (fare: any) => fare?.multiTicketType === MultiTicketTypeEnum.HackerFare
      );
    });
  }
);

export const getHackerFareV2Savings = createSelector(
  getHackerFareV2Flights,
  getSortedAndFilteredFlights,

  (hackerFareV2FlightsFlightList, originalFlightList) => {
    if (isEmpty(hackerFareV2FlightsFlightList) || isEmpty(originalFlightList)) {
      return;
    }
    const hackerFareV2FlightsFlightListCopy = cloneDeep(
      hackerFareV2FlightsFlightList
    );
    const sortedHackerFareV2Flights = sorters.orderByPriceV2(
      hackerFareV2FlightsFlightListCopy
    );
    const hfv2Value =
      sortedHackerFareV2Flights[0]?.fares[0]?.amount?.fiat?.value;
    const flightValue = originalFlightList[0]?.fares[0]?.amount?.fiat?.value;
    return flightValue - hfv2Value;
  }
);

export const getHopperViSavings = createSelector(
  getViFlights,
  getSortedAndFilteredFlights,

  (viFlightList, flightList) => {
    if (isEmpty(viFlightList) || isEmpty(flightList)) {
      return;
    }
    const flightlist = cloneDeep(viFlightList);
    const sortedViFlightsByPrice = sorters.orderByPriceV2(flightlist);
    const viValue = sortedViFlightsByPrice[0]?.fares[0]?.amount?.fiat?.value;
    const flightValue = flightList[0]?.fares[0]?.amount?.fiat?.value;
    return flightValue - viValue;
  }
);

export const getHopperViTimeDifference = createSelector(
  getViFlights,
  getSortedAndFilteredFlights,
  flightsByFlightShopTypeSelector,
  (viFlightList, flightList, flights) => {
    if (isEmpty(viFlightList) || isEmpty(flightList) || isEmpty(flights)) {
      return;
    }
    const virtualInterlineFlights = cloneDeep(viFlightList);
    const flightsList = cloneDeep(flightList);
    const viMinutes = sorters.getDifferenceByMinute(
      sorters.orderByDurationV2(virtualInterlineFlights, flights)[0],
      flights
    );
    const flightMinutes = sorters.getDifferenceByMinute(
      sorters.orderByDurationV2(flightsList, flights)[0],
      flights
    );
    return viMinutes - flightMinutes;
  }
);

export const getViewedMulticityFlightListPropertiesForV2 = createSelector(
  getAirEntryProperties,
  getSelectedAccount,
  flightShopMulticityProgressSelector,
  multicityFlightsSelector,
  getMulticityFlightList,
  (
    airEntryProps,
    account,
    progress,
    flights,
    flightList
  ): ViewedMulticityFlightListProperties | null => {
    if (!airEntryProps || !account || flightList.length === 0) {
      return null;
    }
    let numberOfFares = 0;

    numberOfFares = flightList
      ? flightList.reduce((fareCount: number, flight: any) => {
          return fareCount + flight.fares.length;
        }, 0)
      : 0;

    let fareSlices = flights?.fareSlices || {};

    let numFaresByShelf = {};

    const fares: FareSliceDeparture[] = flightList.reduce(
      (result, flight) => result.concat(flight.fares),
      []
    );
    for (const fare of fares) {
      let shelf: number = fareSlices[fare.fareSlice]?.fareShelf.value;
      let shelfKey = `shelf${shelf}`;
      if (numFaresByShelf[shelfKey]) {
        numFaresByShelf[shelfKey] += 1;
      } else {
        numFaresByShelf[shelfKey] = 1;
      }
    }

    let sliceIndex: number | undefined;
    let includeFareCountProperties = true;
    switch (progress) {
      case MulticityFlightShopStep.ChooseDeparture0:
      case MulticityFlightShopStep.ChooseDeparture1:
      case MulticityFlightShopStep.ChooseDeparture2:
      case MulticityFlightShopStep.ChooseDeparture3:
      case MulticityFlightShopStep.ChooseDeparture4:
        sliceIndex = progress;
        break;
      default:
        includeFareCountProperties = false;
        sliceIndex = undefined;
        break;
    }

    return {
      ...airEntryProps,
      flights_shown: flightList?.length || undefined,
      fares_shown: includeFareCountProperties ? numberOfFares : undefined,
      slice_index: sliceIndex,
      account_type_selected: account?.accountDisplayName!,
      account_use_type: account?.accountUseType,
      customer_account_role: account?.customerAccountRole,
      account_allow_rewards_redemption: account?.allowRewardsRedemption ?? true,
      shelf_fares: includeFareCountProperties ? numFaresByShelf : undefined,
      shelf0_fares: includeFareCountProperties
        ? numFaresByShelf["shelf0"]
        : undefined,
      shelf1_fares: includeFareCountProperties
        ? numFaresByShelf["shelf1"]
        : undefined,
      shelf2_fares: includeFareCountProperties
        ? numFaresByShelf["shelf2"]
        : undefined,
      shelf3_fares: includeFareCountProperties
        ? numFaresByShelf["shelf3"]
        : undefined,
      shelf4_fares: includeFareCountProperties
        ? numFaresByShelf["shelf4"]
        : undefined,
    };
  }
);
export const getViewedFlightListPropertiesForV2 = createSelector(
  predictionSelector,
  getAirEntryProperties,
  getSelectedAccount,
  flightShopProgressSelector,
  flightsSelector,
  getFlightList,
  priceFreezeUserSelectedDurationPropertiesSelector,
  getViFlights,
  getHopperViSavings,
  getHopperViTimeDifference,
  getHackerFareV2Flights,
  getHackerFareV2Savings,
  filteredFlightsSelectorV2,
  (
    prediction,
    airEntryProps,
    account,
    progress,
    flights,
    flightList,
    priceFreezeUserSelectedDurationProperties,
    viFlightList,
    hopperSavingsVi,
    viFlightsTimeDifference,
    hackerFareV2FlightList,
    hopperSavingsHackerFareV2,
    filteredFlights
  ): ViewedFlightListProperties | null => {
    if (!airEntryProps || !account || flightList.length === 0) {
      return null;
    }
    let numberOfFares = 0;

    numberOfFares = flightList
      ? flightList.reduce((fareCount: number, flight: any) => {
          return fareCount + flight.fares.length;
        }, 0)
      : 0;

    let fareSlices = flights?.fareSlices || [];

    let numOutboundFaresByShelf = {};
    if (progress === FlightShopStep.ChooseDeparture) {
      const outboundFares: OutboundFares[] = flightList.reduce(
        (result, flight) => result.concat(flight.fares),
        []
      );
      for (const outboundFare of outboundFares) {
        let shelf: number = fareSlices[outboundFare.fareSlice]?.fareShelf.value;
        let shelfKey = `shelf${shelf}`;
        if (numOutboundFaresByShelf[shelfKey]) {
          numOutboundFaresByShelf[shelfKey] += 1;
        } else {
          numOutboundFaresByShelf[shelfKey] = 1;
        }
      }
    }

    let numReturnFaresByShelf = {};
    if (progress === FlightShopStep.ChooseReturn) {
      const returnFares: FlightFare[] = flightList.reduce(
        (result, flight) => result.concat(flight.fares),
        []
      );
      for (const returnFare of returnFares) {
        let shelf: number = fareSlices[returnFare.return!]?.fareShelf.value;
        let shelfKey = `shelf${shelf}`;
        if (numReturnFaresByShelf[shelfKey]) {
          numReturnFaresByShelf[shelfKey] += 1;
        } else {
          numReturnFaresByShelf[shelfKey] = 1;
        }
      }
    }

    return {
      ...airEntryProps,
      ...priceFreezeUserSelectedDurationProperties,
      flights_shown: flightList?.length || undefined,
      fares_shown_outbound:
        progress === FlightShopStep.ChooseDeparture ? numberOfFares : undefined,
      fares_shown_return:
        progress === FlightShopStep.ChooseReturn ? numberOfFares : undefined,
      slice_direction:
        progress === FlightShopStep.ChooseReturn
          ? "return"
          : progress === FlightShopStep.ChooseDeparture
          ? "outbound"
          : undefined,
      account_type_selected: account?.accountDisplayName!,
      account_use_type: account?.accountUseType,
      customer_account_role: account?.customerAccountRole,
      account_allow_rewards_redemption: account?.allowRewardsRedemption ?? true,
      dealness: prediction?.dealness!,
      recommendation: !prediction
        ? "null"
        : prediction.dealness === Dealness.Wait
        ? "wait"
        : "buy",
      shelf_fares:
        progress === FlightShopStep.ChooseReturn
          ? numReturnFaresByShelf
          : progress === FlightShopStep.ChooseDeparture
          ? numOutboundFaresByShelf
          : undefined,
      shelf0_fares:
        progress === FlightShopStep.ChooseReturn
          ? numReturnFaresByShelf["shelf0"]
          : progress === FlightShopStep.ChooseDeparture
          ? numOutboundFaresByShelf["shelf0"]
          : undefined,
      shelf1_fares:
        progress === FlightShopStep.ChooseReturn
          ? numReturnFaresByShelf["shelf1"]
          : progress === FlightShopStep.ChooseDeparture
          ? numOutboundFaresByShelf["shelf1"]
          : undefined,
      shelf2_fares:
        progress === FlightShopStep.ChooseReturn
          ? numReturnFaresByShelf["shelf2"]
          : progress === FlightShopStep.ChooseDeparture
          ? numOutboundFaresByShelf["shelf2"]
          : undefined,
      shelf3_fares:
        progress === FlightShopStep.ChooseReturn
          ? numReturnFaresByShelf["shelf3"]
          : progress === FlightShopStep.ChooseDeparture
          ? numOutboundFaresByShelf["shelf3"]
          : undefined,
      shelf4_fares:
        progress === FlightShopStep.ChooseReturn
          ? numReturnFaresByShelf["shelf4"]
          : progress === FlightShopStep.ChooseDeparture
          ? numOutboundFaresByShelf["shelf4"]
          : undefined,
      // VI Added properties
      includes_hopper_vi: Boolean(viFlightList.length),
      hopper_vi_savings_usd: hopperSavingsVi,
      hopper_vi_duration_savings_minutes: viFlightsTimeDifference,
      hopper_vi_flights_shown: viFlightList.length || undefined,
      // HFv2 Added properties
      includes_hackerfare: Boolean(hackerFareV2FlightList.length),
      hackerfare_savings_usd: hopperSavingsHackerFareV2,
      hackerfare_flights_shown: hackerFareV2FlightList.length,
      no_availability: !filteredFlights(false).length,
    };
  }
);

export const getSelectedMulticitySliceProperties = createSelector(
  getViewedMulticityFlightListPropertiesForV2,
  selectedTripSelector,
  (viewedMulticityFlightListPropertiesForV2, selectedTrip): {} | null => {
    if (!viewedMulticityFlightListPropertiesForV2) {
      return null;
    }

    const selectedOWRTrip = selectedTrip as ISelectedMulticityTrip;
    return {
      ...viewedMulticityFlightListPropertiesForV2,
      fare_class: getHopperFareRatingName(selectedOWRTrip.departureFareRating),
    };
  }
);

export const getSelectedOutgoingSlicePropertiesV2 = createSelector(
  getViewedFlightListPropertiesForV2,
  selectedTripSelector,
  getCorporateTravelProperties,
  (
    viewedForecastProperties,
    selectedTrip,
    corporateTravelProperties
  ): SelectedSliceProperties | null => {
    if (!viewedForecastProperties) {
      return null;
    }

    const selectedOWRTrip = selectedTrip as ISelectedTrip;
    return {
      ...viewedForecastProperties,
      fare_class: getHopperFareRatingName(selectedOWRTrip.outgoingFareRating),
      ...(isCorpTenant(config.TENANT) && corporateTravelProperties),
    };
  }
);

export const getSelectedReturnSlicePropertiesV2 = createSelector(
  getViewedFlightListPropertiesForV2,
  selectedTripSelector,
  getCorporateTravelProperties,
  (
    viewedForecastProperties,
    selectedTrip,
    corporateTravelProperties
  ): SelectedSliceProperties | null => {
    if (!viewedForecastProperties) {
      return null;
    }

    const selectedOWRTrip = selectedTrip as ISelectedTrip;

    return {
      ...viewedForecastProperties,
      fare_class: getHopperFareRatingName(selectedOWRTrip.returnFareRating),
      ...(isCorpTenant(config.TENANT) && corporateTravelProperties),
    };
  }
);

export const getCfarAttachProperties = createSelector(
  cfarOfferSelector,
  selectedCfarIdSelector,
  getPassengersTotal,
  currentCfarChangePolicySelector,
  fetchAncillaryOfferCallStateSelector,
  refundableFaresPropertiesSelector,
  (
    cfarOffer,
    selectedCfarId,
    passengers,
    changePolicy,
    fetchAncillaryOfferCallState,
    refundableFaresProperties
  ): CfarAttachProperties => {
    const selectedCfar =
      cfarOffer && cfarOffer.length > 0
        ? cfarOffer.find((offer) => isEqual(offer.id, selectedCfarId)) ??
          cfarOffer[0]
        : undefined;
    const properties = {
      offered_cfar_per_pax: selectedCfar
        ? selectedCfar.premiumPerPax.fiat.value
        : undefined,
      cfar_product_id: selectedCfar?.id.productId,
      cfar_quote_id: selectedCfar?.id.quoteId,
      offered_cfar_product_id: cfarOffer
        ? cfarOffer.map((offer) => offer.id.productId)
        : undefined,
      cfar_policy_id: selectedCfar?.id.policyId,
      offered_cfar_policy_id: cfarOffer
        ? cfarOffer.map((offer) => offer.id.policyId)
        : undefined,
      cfar_coverage_percentage:
        selectedCfar?.policyData?.cashCoveragePercentage,
      cfar_premium_percentage: selectedCfar?.policyData?.premiumPercentage,
      cfar_choice:
        cfarOffer && cfarOffer.length > 0
          ? selectedCfarId &&
            selectedCfarId.policyId !== DO_NOT_APPLY_OPTION_KEY
            ? 1
            : 0
          : refundableFaresProperties.cfar_choice ?? "none",
      // TODO: TBD Multi-ticket tracking https://hopper-jira.atlassian.net/browse/BF-962
      cancellation_policy: getFtcTypeArr(changePolicy)[0] ?? undefined,
      cfar_shop_failed: fetchAncillaryOfferCallState === CallState.Failed,
    };

    return {
      ...properties,
      // note: the following properties are supposed to be hidden when the user does NOT attach CFAR
      ...(properties.cfar_choice === 1
        ? {
            charged_cfar_total: selectedCfar
              ? selectedCfar.premiumPerPax.fiat.value * passengers
              : undefined,
            charged_cfar_per_pax: selectedCfar
              ? selectedCfar.premiumPerPax.fiat.value
              : undefined,
            attached_cfar_product_id: selectedCfar?.id.productId,
            attached_cfar_policy_id: selectedCfar?.id.policyId,
          }
        : undefined),
    } as CfarAttachProperties;
  }
);

export const getTripAndFareClassFactsProperties = createSelector(
  getViewedFlightListPropertiesForV2,
  selectedTripSelector,
  selectedTripDetailsSelector,
  getPassengersTotal,
  (
    viewedForecastProperties,
    selectedTrip,
    tripDetails,
    passengers
  ): TripAndFareClassFactsProperties => {
    const selectedOWRTrip = selectedTrip as ISelectedTrip;
    const fareId = selectedOWRTrip?.returnFareId
      ? selectedOWRTrip.returnFareId
      : selectedOWRTrip.outgoingFareId;
    const selectedFare = tripDetails?.fareDetails.find(
      (fare) => fare.id == fareId
    );

    return {
      lob: "air",
      origin: viewedForecastProperties?.origin,
      advance:
        tripDetails && tripDetails.slices.length > 0
          ? dayjs(tripDetails.slices[0].departureTime).diff(dayjs(), "day")
          : undefined,
      carrier:
        selectedFare?.slices[0]?.fareDetails.segments[0]?.validatingCarrierCode,
      fare_type: selectedFare?.slices[0]?.fareShelf?.brandName,
      destination: viewedForecastProperties?.destination,
      return_date: viewedForecastProperties?.return_date,
      departure_date: viewedForecastProperties?.departure_date,
      total_stops:
        tripDetails?.slices?.[0]?.stops !== undefined
          ? tripDetails?.slices?.[0]?.stops +
            (tripDetails?.slices?.[1]?.stops ?? 0)
          : undefined,
      price_per_pax: selectedFare?.paxPricings?.[0]?.pricing.total?.fiat.value,
      searched_pax_total: passengers,
      fare_class: selectedFare
        ? getHopperFareRatingName(selectedFare.slices[0]?.fareShelf?.rating)
        : undefined,
    };
  }
);

export const dpOfferPropertiesSelector = createSelector(
  disruptionProtectionOfferSelector,
  selectedDisruptionProtectionIdSelector,
  selectedTripDetailsSelector,
  isDisruptionProtectionAvailableSelector,
  (
    dpOffers,
    selectedDpId,
    tripDetails,
    isDisruptionProtectionAvailable
  ): Cap1DpOfferFactsProperties => {
    const dpOffer: MissedConnectionOffer | DelayOffer | undefined =
      isDisruptionProtectionAvailable && dpOffers && dpOffers.length > 0
        ? dpOffers.find((offer) => isEqual(offer.id, selectedDpId)) ??
          dpOffers[0]
        : undefined;

    const dpProductType: "delays" | "missed_connections" | undefined =
      dpOffer?.AncillaryOffer === AncillaryOfferEnum.DelayOffer
        ? "delays"
        : dpOffer?.AncillaryOffer === AncillaryOfferEnum.MissedConnectionOffer
        ? "missed_connections"
        : undefined;

    const disruptionChoice: "0" | "1" | "none" | undefined =
      isDisruptionProtectionAvailable
        ? selectedDpId && selectedDpId.policyId !== DO_NOT_APPLY_OPTION_KEY
          ? "1"
          : "0"
        : "none";

    const chargedCfarProperties =
      disruptionChoice === "1"
        ? {
            charged_disruption_total_usd:
              dpOffer?.premiumPricing.totalPrices.fiat.value,
            charged_disruption_per_pax:
              dpOffer?.premiumPricing.perPassengerPrices.fiat.value,
          }
        : undefined;

    return {
      disruption_product: dpProductType,
      disruption_choice: disruptionChoice,
      disruption_per_pax: dpOffer?.premiumPricing.perPassengerPrices.fiat.value,
      disruption_policy_id: dpOffer?.id.policyId,
      disruption_product_id: dpOffer?.id.productId,
      disruption_total: dpOffer?.premiumPricing.totalPrices.fiat.value,
      outbound_segments: tripDetails?.slices?.[0]?.segmentDetails.length,
      outbound_stops: tripDetails?.slices?.[0]?.stops,
      return_segments: tripDetails?.slices?.[1]?.segmentDetails.length,
      return_stops: tripDetails?.slices?.[1]?.stops,
      total_stops:
        tripDetails?.slices?.[0]?.stops !== undefined
          ? tripDetails?.slices?.[0]?.stops +
            (tripDetails?.slices?.[1]?.stops ?? 0)
          : undefined,
      ...chargedCfarProperties,
    };
  }
);

export const cfarAttachPropertiesForViewBookingAddOnSelector = createSelector(
  getCfarAttachProperties,
  (cfarAttachProperties) => {
    return {
      cfar_policy_id: cfarAttachProperties.cfar_policy_id,
      cfar_product_id: cfarAttachProperties.cfar_product_id,
      cfar_quote_id: cfarAttachProperties.cfar_quote_id,
      offered_cfar_per_pax: cfarAttachProperties.offered_cfar_per_pax,
      cfar_coverage_percentage: cfarAttachProperties.cfar_coverage_percentage,
      cfar_premium_percentage: cfarAttachProperties.cfar_premium_percentage,
      cfar_shop_failed: cfarAttachProperties.cfar_shop_failed,
      cancellation_policy: cfarAttachProperties.cancellation_policy,
    };
  }
);

export const getViewedTripSummaryProperties = createSelector(
  getSelectedOutgoingSliceProperties,
  isOutgoingMultiTicketSelector,
  isReturnMultiTicketSelector,
  selectedTripDetailsSelector,
  getFlightShopEntryPoint,
  getHopperViSavings,
  getHopperViTimeDifference,
  (
    viewedSliceProperties,
    outgoingHackerFare,
    returnHackerFare,
    selectedTripDetails,
    flightShopEntryPoint,
    hopperSavingsVi,
    viFlightsTimeDifference
  ): ViewedTripSummaryProperties => {
    const multiTicketType = selectedTripDetails?.fareDetails[0].multiTicketType;
    const isViMultiTicketType = multiTicketType === MultiTicketTypeEnum.VI;
    return {
      ...viewedSliceProperties,
      is_multi_ticket: outgoingHackerFare || returnHackerFare,
      multi_ticket_type: multiTicketType,
      carrier: selectedTripDetails?.validatingCarrierCode || "",
      entry_type: flightShopEntryPoint,
      // VI Added properties
      ...(isViMultiTicketType && {
        hopper_vi_savings_usd: hopperSavingsVi,
        hopper_vi_duration_savings_minutes: viFlightsTimeDifference,
      }),
    };
  }
);

export const cfarDiscountPropertiesSelector = createSelector(
  selectedDiscountedCfarOfferPricesSelector,
  (discountCfar) => ({
    original_premium: discountCfar?.originalPremiumAmount.fiat.value,
    discount_percentage: discountCfar?.discountPercentage,
  })
);

export const dpOfferPropertiesForViewBookingAddOnSelector = createSelector(
  dpOfferPropertiesSelector,
  (dpOfferProperties) => {
    return {
      disruption_product: dpOfferProperties.disruption_product,
      disruption_per_pax: dpOfferProperties.disruption_per_pax,
      disruption_policy_id: dpOfferProperties.disruption_policy_id,
      disruption_product_id: dpOfferProperties.disruption_product_id,
      disruption_total: dpOfferProperties.disruption_total,
      outbound_segments: dpOfferProperties.outbound_segments,
      outbound_stops: dpOfferProperties.outbound_stops,
      return_segments: dpOfferProperties.return_segments,
      return_stops: dpOfferProperties.return_stops,
    };
  }
);

export const viewBookingAddOnPropertiesSelector = createSelector(
  getTripAndFareClassFactsProperties,
  getViewedTripSummaryProperties,
  (tripAndFareClassFactsProperties, viewedTripSummaryProperties) => {
    return {
      lob: tripAndFareClassFactsProperties.lob,
      origin: tripAndFareClassFactsProperties.origin,
      advance: tripAndFareClassFactsProperties.advance,
      carrier: tripAndFareClassFactsProperties.carrier,
      fare_type: tripAndFareClassFactsProperties.fare_type,
      destination: tripAndFareClassFactsProperties.destination,
      return_date: tripAndFareClassFactsProperties.return_date,
      departure_date: tripAndFareClassFactsProperties.departure_date,
      total_stops: tripAndFareClassFactsProperties.total_stops,
      price_per_pax: tripAndFareClassFactsProperties.price_per_pax,
      searched_pax_total: tripAndFareClassFactsProperties.searched_pax_total,
      fare_class: tripAndFareClassFactsProperties.fare_class,
      is_multi_ticket: viewedTripSummaryProperties.is_multi_ticket,
    };
  }
);

export const cfarAddOnChoicePropertiesSelector = createSelector(
  getCfarAttachProperties,
  getTripAndFareClassFactsProperties,
  getViewedTripSummaryProperties,
  (
    cfarAttachProperties,
    tripAndFareClassFactsProperties,
    viewedTripSummaryProperties
  ) => {
    return {
      lob: tripAndFareClassFactsProperties.lob,
      cfar_choice: cfarAttachProperties.cfar_choice,
      cancellation_policy: cfarAttachProperties.cancellation_policy,
      is_multi_ticket: viewedTripSummaryProperties.is_multi_ticket,
    };
  }
);

export const dpAddOnChoicePropertiesSelector = createSelector(
  dpOfferPropertiesSelector,
  (dpOfferProperties): Cap1DisruptionAddOnChoiceProperties => {
    const {
      charged_disruption_per_pax,
      charged_disruption_total_usd,
      ...dpOfferPropertiesWithoutCharged
    } = dpOfferProperties;

    return { ...dpOfferPropertiesWithoutCharged };
  }
);

// Corporate Travel

export const getIsAutoApprovalEnabled = (state: IStoreState) =>
  state.flightAncillary.isAutoApprovalEnabled;

export const getAllowRewardsWithPolicy = createSelector(
  getAllowRewardsRedemptionInAnyAccount,
  getIsAutoApprovalEnabled,
  getCorporateTravel,
  (allowRewardsAccounts, isAutoApprovalEnabled, corporateTravel): boolean => {
    if (isCorpTenant(config.TENANT)) {
      return (
        allowRewardsAccounts &&
        (corporateTravel?.policyCompliance.isInPolicy || isAutoApprovalEnabled)
      );
    }
    return true;
  }
);

const getSelectedMarketingAirlineCodes = (state: IStoreState) =>
  state.flightShop.selectedMarketingAirlineCodes;
const getSelectedOperatingAirlineCodes = (state: IStoreState) =>
  state.flightShop.selectedOperatingAirlineCodes;

export const isSpiritOrFrontierAirlinesSelectedSelector = createSelector(
  getSelectedMarketingAirlineCodes,
  getSelectedOperatingAirlineCodes,
  isCfarF9NKEnabledSelector,
  (marketingAirlineCodes, operatingAirlineCodes, isCfarF9NKEnabled) => {
    return (
      isCfarF9NKEnabled &&
      (marketingAirlineCodes.includes("NK") ||
        marketingAirlineCodes.includes("F9") ||
        operatingAirlineCodes.includes("NK") ||
        operatingAirlineCodes.includes("F9"))
    );
  }
);

export const getAllowTravelWalletWithPolicy = createSelector(
  getCorporateTravel,
  getIsAutoApprovalEnabled,
  (corporateTravel, isAutoApprovalEnabled): boolean => {
    if (isCorpTenant(config.TENANT)) {
      return (
        corporateTravel?.policyCompliance.isInPolicy || isAutoApprovalEnabled
      );
    }
    return true;
  }
);
