import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { RouteComponentProps } from "react-router";
import { FlightFreezeSummaryConnectorProps } from "./container";

import "./styles.scss";
import * as t from "./textConstants";
import {
  ActionLink,
  Icon,
  IconName,
  useDeviceTypes,
  NotificationBanner,
  BannerSeverity,
} from "halifax";
import { PATH_PRICE_FREEZE } from "../../../../utils/urlPaths";
import { getDurationText } from "../../../shop/constants";
import { PRICE_FREEZE_VOID_WINDOW_CONTROL } from "../../../../context/experiments";
import dayjs from "dayjs";
import { MobileFlightFreezeWorkflowStep } from "../MobileFlightFreezeWorkflow/component";
import { PriceFreezeDurationModal } from "../priceFreezeDurationComponents/PriceFreezeDurationModal";

export interface IFlightFreezeSummaryProps
  extends RouteComponentProps,
    FlightFreezeSummaryConnectorProps {
  children?: React.ReactNode;
  isMobile?: boolean;
  displayPriceFreezeChangesContent: boolean;
  useLockPriceLanguage?: boolean;
  isFromFlightShopReviewItinerary: boolean;
  /**
   * This is the price freeze mobile `purchaseStep` the customer is on.
   * On mobile, the checkout is not all on one page.
   */
  purchaseStep?: MobileFlightFreezeWorkflowStep;
  /**
   * Sets the price freeze mobile `purchaseStep`.
   * On mobile, the checkout is not all on one page.
   */
  setPurchaseStep?: React.Dispatch<
    React.SetStateAction<MobileFlightFreezeWorkflowStep>
  >;
}

export const FlightFreezeSummary = ({
  children,
  isMobile,
  displayPriceFreezeChangesContent,
  priceFreezeCap,
  priceFreezeDuration,
  priceFreezeFiat,
  rewardsKey,
  priceFreezeOfferRewards,
  singlePassengerPrices,
  isPriceFreezeDurationActive,
  useLockPriceLanguage,
  isFromFlightShopReviewItinerary,
  isPriceFreezeDurationPopupEnabled,
  priceFreezeVoidWindow,
  priceFreezeVoidWindowEnd,
  isPriceFreezeDurationShortActive,
  isPriceFreezeDurationLongActive,
  isPriceFreezeShowDurationsVariant1Active,
  customPriceFreezeOffer,
  purchaseStep,
  setPurchaseStep,
}: IFlightFreezeSummaryProps) => {
  const { matchesMobile, matchesDesktop } = useDeviceTypes();
  const [priceFreezeDurationModalOpen, setPriceFreezeDurationModalOpen] =
    useState<boolean>(false);
  const prePopulatedRewardsKey = rewardsKey;
  const explanationTexts = t.PRICE_FREEZE_EXPLANATION_TEXTS(
    priceFreezeCap,
    useLockPriceLanguage
  );

  const isFrozenPriceDurationHidden =
    matchesMobile && !isPriceFreezeDurationActive;

  const showDurationForShortDurations =
    matchesMobile &&
    isPriceFreezeDurationActive &&
    isPriceFreezeDurationShortActive;

  const isFeePerTravelerHidden = matchesMobile && !isPriceFreezeDurationActive;

  const isEditDurationEnabled =
    (matchesMobile && isPriceFreezeDurationActive) ||
    (matchesDesktop && isPriceFreezeDurationPopupEnabled);

  const showFeeSection =
    isFeePerTravelerHidden ||
    showDurationForShortDurations ||
    (matchesMobile && isPriceFreezeDurationLongActive) ||
    (!isPriceFreezeDurationActive && matchesDesktop) ||
    isFromFlightShopReviewItinerary;

  const showDurationSection =
    isFrozenPriceDurationHidden ||
    showDurationForShortDurations ||
    (isPriceFreezeDurationLongActive && matchesMobile);

  let showVoidWindowBanner;
  if (priceFreezeVoidWindowEnd) {
    if (priceFreezeVoidWindowEnd === "Unavailable") {
      showVoidWindowBanner = false;
    } else {
      showVoidWindowBanner = dayjs().isBefore(dayjs(priceFreezeVoidWindowEnd));
    }
  }

  return (
    <>
      <Box className={clsx("flight-freeze-summary-root", { mobile: isMobile })}>
        <Box className={clsx("freeze-summary", { mobile: isMobile })}>
          <Box className={clsx("container", "frozen-price")}>
            <Typography variant="caption" className="title">
              {t.CURRENT_PRICE_FROZEN(useLockPriceLanguage)}
            </Typography>
            <Typography
              variant="body1"
              className="subtitle"
              dangerouslySetInnerHTML={{
                __html: singlePassengerPrices
                  ? t.SINGLE_PASSENGER_PRICES_TEXT(
                      singlePassengerPrices.fiat,
                      singlePassengerPrices.rewards,
                      prePopulatedRewardsKey,
                      undefined
                    )
                  : "...",
              }}
            />
          </Box>
          {!isPriceFreezeDurationActive && matchesDesktop && (
            <Box className={clsx("container", "frozen-duration")}>
              <Typography variant="caption" className="title">
                {t.FROZEN_DURATION(useLockPriceLanguage)}
              </Typography>
              <Box className="cta-section">
                <Typography variant="body1" className="subtitle">
                  <strong>
                    {priceFreezeDuration
                      ? getDurationText(priceFreezeDuration)
                      : "..."}
                  </strong>
                </Typography>
                {isEditDurationEnabled && (
                  <ActionLink
                    className="edit-duration-button"
                    label={t.EDIT_DURATION}
                    onClick={() => setPriceFreezeDurationModalOpen(true)}
                    content={
                      <Typography className="button-copy" variant="body1">
                        {t.EDIT_DURATION}
                      </Typography>
                    }
                  />
                )}
              </Box>
            </Box>
          )}
          {(showDurationSection && !isPriceFreezeShowDurationsVariant1Active) ||
            (isFromFlightShopReviewItinerary && (
              <Box className={clsx("container", "frozen-duration")}>
                <Typography variant="caption" className="title">
                  {t.FROZEN_DURATION(useLockPriceLanguage)}
                </Typography>
                <Box className="cta-section">
                  <Typography variant="body1" className="subtitle">
                    <strong>
                      {priceFreezeDuration
                        ? getDurationText(priceFreezeDuration)
                        : "..."}
                    </strong>
                  </Typography>
                  {isEditDurationEnabled && (
                    <ActionLink
                      className="edit-duration-button"
                      label={t.EDIT_DURATION}
                      onClick={() => setPriceFreezeDurationModalOpen(true)}
                      content={
                        <Typography className="button-copy" variant="body1">
                          {t.EDIT_DURATION}
                        </Typography>
                      }
                    />
                  )}
                </Box>
                {matchesMobile &&
                  priceFreezeVoidWindow !== PRICE_FREEZE_VOID_WINDOW_CONTROL &&
                  showVoidWindowBanner &&
                  (priceFreezeDuration?.inSeconds ===
                    t.PRICE_FREEZE_DURATION_12_HOURS_IN_SECONDS ||
                    priceFreezeDuration?.inSeconds ===
                      t.PRICE_FREEZE_DURATION_24_HOURS_IN_SECONDS) && (
                    <Box>
                      <NotificationBanner
                        className="void-window-banner"
                        severity={BannerSeverity.NOTICE}
                        icon={<Icon name={IconName.AlertIcon} />}
                        content={
                          <Typography
                            className="void-window-label"
                            variant="caption"
                          >
                            <span className="label-copy">
                              {t.DURATION_VOID_WINDOW_TEXT}
                            </span>
                          </Typography>
                        }
                      />
                    </Box>
                  )}
              </Box>
            ))}
          {showFeeSection && (
            <Box className={clsx("container", "fee")}>
              <Typography variant="caption" className="title">
                {t.FEE_TRAVELER}
              </Typography>
              <Typography variant="body1" className="subtitle">
                <strong>{priceFreezeFiat}</strong>
                {` ${t.FREEZE_REWARDS(
                  priceFreezeOfferRewards,
                  prePopulatedRewardsKey,

                  undefined
                )}`}
              </Typography>
            </Box>
          )}
        </Box>
        {children && children}
        {displayPriceFreezeChangesContent && (
          <Box
            className={clsx("freeze-changes-container", { mobile: isMobile })}
          >
            {matchesDesktop &&
              !isPriceFreezeDurationShortActive &&
              !isPriceFreezeDurationLongActive && (
                <Box className={clsx("void-window-banner")}>
                  {priceFreezeVoidWindow !== PRICE_FREEZE_VOID_WINDOW_CONTROL &&
                    showVoidWindowBanner &&
                    (priceFreezeDuration?.inSeconds ===
                      t.PRICE_FREEZE_DURATION_12_HOURS_IN_SECONDS ||
                      priceFreezeDuration?.inSeconds ===
                        t.PRICE_FREEZE_DURATION_24_HOURS_IN_SECONDS) && (
                      <Box>
                        <NotificationBanner
                          severity={BannerSeverity.NOTICE}
                          icon={
                            <Icon
                              name={IconName.AlertIcon}
                              className="void-window-icon"
                            />
                          }
                          content={
                            <Typography
                              className="void-window-label"
                              variant="caption"
                            >
                              <span className="label-copy">
                                {t.DURATION_VOID_WINDOW_TEXT}
                              </span>
                            </Typography>
                          }
                        />
                      </Box>
                    )}
                </Box>
              )}
            <Box className={clsx("increase", "copy-container")}>
              <div>
                <Icon className="icon" name={IconName.PriceFreezeArrow} />
              </div>
              <Typography className="text" variant="body1">
                <span
                  dangerouslySetInnerHTML={{
                    __html: explanationTexts.increaseText,
                  }}
                ></span>
              </Typography>
            </Box>
            <Box className={clsx("decrease", "copy-container")}>
              <div>
                <Icon className="icon" name={IconName.PriceFreezeArrow} />
              </div>
              <Typography className="text" variant="body1">
                <span
                  dangerouslySetInnerHTML={{
                    __html: explanationTexts.decreaseText,
                  }}
                ></span>
              </Typography>
            </Box>
            <Box className={clsx("similar-flight", "copy-container")}>
              <div>
                <Icon className="icon" name={IconName.CheckCircleFilledBlue} />
              </div>
              <Typography className="text" variant="body1">
                <span
                  dangerouslySetInnerHTML={{
                    __html: explanationTexts.similarFlightText,
                  }}
                ></span>
              </Typography>
            </Box>
          </Box>
        )}
        {isPriceFreezeShowDurationsVariant1Active &&
          isFromFlightShopReviewItinerary &&
          matchesDesktop && (
            <Box className={clsx("void-window-banner")}>
              {priceFreezeVoidWindow !== PRICE_FREEZE_VOID_WINDOW_CONTROL &&
                showVoidWindowBanner &&
                (customPriceFreezeOffer?.timeToLive.inSeconds ===
                  t.PRICE_FREEZE_DURATION_12_HOURS_IN_SECONDS ||
                  customPriceFreezeOffer?.timeToLive.inSeconds ===
                    t.PRICE_FREEZE_DURATION_24_HOURS_IN_SECONDS) && (
                  <Box>
                    <NotificationBanner
                      severity={BannerSeverity.NOTICE}
                      icon={
                        <Icon
                          name={IconName.AlertIcon}
                          className="void-window-icon"
                        />
                      }
                      content={
                        <Typography
                          className="void-window-label"
                          variant="caption"
                        >
                          <span className="label-copy">
                            {t.DURATION_VOID_WINDOW_TEXT}
                          </span>
                        </Typography>
                      }
                    />
                  </Box>
                )}
            </Box>
          )}
        <Box className="terms-container">
          <ActionLink
            className="cta-button"
            onClick={() =>
              window.open(`${PATH_PRICE_FREEZE}`, "_blank", "noopener")
            }
            content={
              <Typography className="cta-text" variant="body1">
                <span>{t.READ_TERMS}</span>
              </Typography>
            }
          />
        </Box>
      </Box>
      {isEditDurationEnabled && (
        <PriceFreezeDurationModal
          priceFreezeDurationModalOpen={priceFreezeDurationModalOpen}
          setPriceFreezeDurationModalOpen={setPriceFreezeDurationModalOpen}
          purchaseStep={purchaseStep}
          setPurchaseStep={setPurchaseStep}
        />
      )}
    </>
  );
};
