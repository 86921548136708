import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { CorpPriceQuoteData } from "redmond";
import {
  setSubmitForApproval,
  setSubmitForApprovalFailure,
  setTripPurpose,
} from "../../../actions/actions";

import { IStoreState } from "../../../../../reducers/types";
import { CorpDesktopFlightBookWorkflow } from "./component";
import {
  getCardPaymentAccount,
  getPolicyLimit,
  getPriceQuoteWithUpdatedAncillary,
  getUserSelectedPassengerIds,
} from "../../../reducer";
import { isSinglePageMarketplaceEnabledSelector } from "../../../../ancillary/reducer/selectors";
import {
  getCorporateTravel,
  getShopRequest,
  getViewedTripSummaryProperties,
  selectedFareDetailsSelector,
} from "../../../../shop/reducer/selectors";
import {
  mapStateToProps as baseMapStateToProps,
  mapDispatchToProps as baseMapDispatchToProps,
} from "../../capone/DesktopFlightBookWorkflow/container";

const mapStateToProps = (state: IStoreState) => {
  return {
    ...baseMapStateToProps(state),
    corporateTravel: getCorporateTravel(state),
    priceQuote: getPriceQuoteWithUpdatedAncillary(state) as CorpPriceQuoteData,
    isSinglePageMarketplaceEnabled:
      isSinglePageMarketplaceEnabledSelector(state),
    cardPaymentAccount: getCardPaymentAccount(state),
    policyLimit: getPolicyLimit(state),
    fareDetails: selectedFareDetailsSelector(state),
    selectedPaxIds: getUserSelectedPassengerIds(state),
    shopRequest: getShopRequest(state),
    viewedTripSummaryProperties: getViewedTripSummaryProperties(state),
  };
};

const mapDispatchToProps = {
  ...baseMapDispatchToProps,
  setSubmitForApproval,
  setSubmitForApprovalFailure,
  setTripPurpose,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CorpDesktopFlightBookWorkflowConnectorProps = ConnectedProps<
  typeof connector
>;

export const CorpConnectedDesktopFlightBookWorkflow = withRouter(
  connector(CorpDesktopFlightBookWorkflow)
);
